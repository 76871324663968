import { isUUID } from 'validator'

import { logger as loggerDev } from 'src/lib/logger'

import { SidebarActions, SidebarMode } from './SidebarContext'
import type { SidebarAction, SidebarState } from './SidebarContext'

const loggingEnabled = false

const logger = loggingEnabled
  ? loggerDev
  : {
      dev: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    }

export const sidebarReducer = (state: SidebarState, action: SidebarAction) => {
  const isCustomPropertyUpdate = action?.payload?.propertyId
    ? isUUID(action?.payload?.propertyId)
    : false
  const propertiesSection = isCustomPropertyUpdate ? 'custom' : 'standard'

  logger.dev(`SIDEBAR: ${action?.type || 'UNKNOWN'}`, {
    payload: action?.payload,
    isCustomPropertyUpdate,
  })

  switch (action.type) {
    case SidebarActions.SET_MODE:
      return { ...state, mode: action.payload }
    case SidebarActions.SET_OBJECT:
      logger.dev('SET_OBJECT', {
        payload: action.payload,
        mode: action.payload.mode || state?.mode || SidebarMode.VIEW,
      })
      if (!action?.payload?.object) {
        return state
      }
      return {
        ...state,
        object: action.payload.object,
        mode: action.payload.mode || state?.mode || SidebarMode.VIEW,
      }
    case SidebarActions.RESET:
      return { ...state, object: null, mode: SidebarMode.VIEW }
    case SidebarActions.UPDATE_PROPERTY:
      return {
        ...state,
        object: {
          ...state.object,
          properties: {
            ...state.object.properties,
            [propertiesSection]: {
              ...(state.object.properties[propertiesSection] as any),
              [action.payload.propertyDefinitionId]: {
                ...(state.object.properties[propertiesSection]?.[
                  action.payload.propertyDefinitionId
                ] || {}),
                value: action.payload.value?.value,
              },
            },
          },
        },
      }
    case SidebarActions.EXAMINE_PROPERTY:
      return {
        ...state,
        mode: SidebarMode.PROPERTY,
        propertyToExamine: action.payload,
      }
  }
}
