import {
  useCallback,
  useState,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from 'react'

import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  IconBrowser,
  IconCheck,
  IconPencil,
  IconSettings,
  IconSparkles,
  IconSquare,
  IconSquareFilled,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import type { ObjectPropertyDefinition } from 'types/graphql'
import { isUUID } from 'validator'

import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  PropertySourceMetadata,
  PropertyTypes,
} from 'src/lib/objects'

import useObjects from '../Objects/useObjects'
import Row from '../Row/Row'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

import { canManageObjectType } from './access'
import { hasOptions } from './formattingAndValidation'
import {
  POPULATE_OBJECT_PROPERTY_FROM_CONTEXT,
  POPULATE_OBJECT_PROPERTY_FROM_WEB,
  UPDATE_OBJECT_PROPERTY,
} from './mutations'
import { PROPERTY_ACTIONS } from './types'

import 'react-phone-number-input/style.css'

const menuItemPrimaryTypographyProps = {
  color: 'text.secondary',
  sx: {
    fontSize: '12px',
    fontWeight: 500,
  },
}

const menuSx = {
  width: '272px',
  '& .MuiList-root': {
    p: 0,
  },
  '& .MuiButtonBase-root': {
    px: '12px',
    py: '8px',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },
}

const PropertySettings = {
  AI: 'AI',
  WEB: 'WEB',
  MORE: 'MORE',
}

const PropertySettingsMetadata = {
  AI: {
    label: 'Day.ai',
    icon: IconSparkles,
    tooltip: {
      false: 'Day.ai will not populate this property',
      true: 'Day.ai will use the available communication and context to reason, decide, & populate value(s) for this property. Your edits will always take precedence.',
    },
  },
  WEB: {
    label: 'Web',
    icon: IconBrowser,
    tooltip: {
      false: 'Web research is not enabled for this property',
      true: 'Public information from the internet will populate this property. Your edits will always take precedence.',
    },
  },
  MORE: {
    label: 'More',
    icon: IconSettings,
    tooltip: {
      false: 'Superadmins can view more settings for this property',
      true: 'Click to open settings for this property',
    },
  },
}

const propertySettingsButtonSx = {
  fontSize: '11px',
  fontWeight: 500,
  letterSpacing: '-0.22px',
  flex: 1,

  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}

const PropertySettingButton = ({
  type,
  enabled,
  onClick,
}: {
  type: string
  enabled: boolean
  onClick: () => void
}) => {
  const { icon, tooltip, label } = PropertySettingsMetadata[type]
  return (
    <Tooltip
      title={tooltip[enabled]}
      arrow={true}
      className="property-setting-button-tooltip"
    >
      <div>
        <Button
          startIcon={React.createElement(icon, { size: 12 })}
          disabled={!enabled}
          onClick={onClick}
          size="small"
          sx={propertySettingsButtonSx}
          className="property-setting-button"
        >
          {label}
        </Button>
      </div>
    </Tooltip>
  )
}

const propertySettingsRowSx = {
  width: '100%',
  justifyContent: 'space-between',
  '& .property-setting-button': {
    flex: 1,
    width: '100%',
  },
  '& .property-setting-button-tooltip': {
    flex: 1,
    width: '100%',
  },
}

const PropertySettingsRow = ({
  propertyDefinition,
  objectId,
  onClose,
}: {
  propertyDefinition: ObjectPropertyDefinition
  objectId: string
  onClose: (_) => void
}) => {
  const { workspaces, setSidebarObject } = useContext(DayContext)

  const propertyWorkspaceForUser = useMemo(() => {
    return (Array.isArray(workspaces) ? workspaces : []).find(
      (workspace) => workspace.id === propertyDefinition.workspaceId
    )
  }, [workspaces, propertyDefinition])

  const userCanManageObjectType = useMemo(() => {
    return canManageObjectType(propertyDefinition, propertyWorkspaceForUser)
  }, [propertyDefinition, propertyWorkspaceForUser])

  const [populateFromWeb] = useMutation(POPULATE_OBJECT_PROPERTY_FROM_WEB)
  const [populateFromContext] = useMutation(
    POPULATE_OBJECT_PROPERTY_FROM_CONTEXT
  )

  const handlePopulateFromWeb = useCallback(() => {
    toast.promise(
      populateFromWeb({
        variables: {
          input: {
            workspaceId: propertyDefinition.workspaceId,
            objectType: propertyDefinition.objectTypeId,
            objectId,
            propertyDefinitionId: propertyDefinition.id,
          },
        },
      }),
      {
        loading: 'Populating from web...',
        success:
          'Off to crawl the web ... updates will appear in a few minutes.',
        error: 'Failed to populate from web',
      }
    )
  }, [populateFromWeb, propertyDefinition, objectId])

  const handlePopulateFromContext = useCallback(() => {
    toast.promise(
      populateFromContext({
        variables: {
          input: {
            workspaceId: propertyDefinition.workspaceId,
            objectType: propertyDefinition.objectTypeId,
            objectId,
            propertyDefinitionId: propertyDefinition.id,
          },
        },
      }),
      {
        loading: 'Populating from context...',
        success: 'Populating from context ... (may take a few minutes)',
        error: 'Failed to populate from context',
      }
    )
  }, [populateFromContext, propertyDefinition, objectId])

  const handleOpenInSidebar = useCallback(() => {
    setSidebarObject({
      objectType: NativeObjectTypes.PropertyDefinition,
      mode: 'edit',
      objectId: propertyDefinition.id,
      workspaceId: propertyDefinition.workspaceId,
      properties: {
        id: propertyDefinition.id,
        name: propertyDefinition.name,
        description: propertyDefinition.description,
        objectTypeId: propertyDefinition.objectTypeId,
        propertyTypeId: propertyDefinition.propertyTypeId,
        propertyType: propertyDefinition.propertyType || {
          id: propertyDefinition.propertyTypeId,
        },
        aiManaged: propertyDefinition.aiManaged,
        useWeb: propertyDefinition.useWeb,
        options: propertyDefinition.options,
        createdAt: propertyDefinition.createdAt,
        updatedAt: propertyDefinition.updatedAt,
      },
    })
    onClose(null)
  }, [setSidebarObject, propertyDefinition, onClose])

  const objectTypeHasWebEnabled = useMemo(() => {
    return ObjectTypeMetadata[propertyDefinition.objectTypeId]?.webEnabled
  }, [propertyDefinition])

  return (
    <Row sx={propertySettingsRowSx}>
      <PropertySettingButton
        type={PropertySettings.AI}
        enabled={propertyDefinition.aiManaged}
        onClick={handlePopulateFromContext}
      />
      {objectTypeHasWebEnabled && (
        <PropertySettingButton
          type={PropertySettings.WEB}
          enabled={propertyDefinition.useWeb}
          onClick={handlePopulateFromWeb}
        />
      )}
      <PropertySettingButton
        type={PropertySettings.MORE}
        enabled={userCanManageObjectType}
        onClick={handleOpenInSidebar}
      />
    </Row>
  )
}

const PropertyEditMenuSxOverrides = {
  [PropertyTypes.Boolean]: {},
  [PropertyTypes.Phone]: {},
  [PropertyTypes.DateTime]: {},
  [PropertyTypes.Combobox]: {},
  [PropertyTypes.Picklist]: {},
  [PropertyTypes.MultiPicklist]: {},
  [PropertyTypes.Email]: {
    width: '340px',
  },
  [PropertyTypes.Url]: {
    width: '340px',
  },
  [PropertyTypes.TextArea]: {
    width: '340px',
  },
  [PropertyTypes.Integer]: {},
  [PropertyTypes.Currency]: {},
  [PropertyTypes.Percent]: {},
  [PropertyTypes.Calculated]: {},
}

const getMenuSx = (propertyTypeId) => {
  const overridesSx = PropertyEditMenuSxOverrides[propertyTypeId]

  return { ...menuSx, ...overridesSx }
}

const reasoningSx = {
  m: '12px',
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',

  '& .reasoning-text': {
    p: 1,
    opacity: 0.8,
    fontSize: '11px',
    fontStyle: 'italic',
  },

  '& .reasoning-info': {
    justifyContent: 'space-between',

    '& .source-type-chip': {
      '& .MuiChip-label': {
        fontSize: '10px',
        fontWeight: 600,
      },
      py: '1px',
      letterSpacing: '-0.22px',
      borderRadius: 0,
      borderTopRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      minHeight: '18px',
      height: '18px',
    },

    '& .MuiTypography-root': {
      fontSize: '10px',
      fontWeight: 600,
      pr: '4px',
      opacity: 0.8,
    },
  },
}

const PropertyReasoningTeaser = (property) => {
  logger.dev('PropertyReasoningTeaser', { property })
  if (property?.value?.reasoning) {
    const icon = PropertySourceMetadata[property?.value?.propertySourceId]?.icon
    const label =
      PropertySourceMetadata[property?.value?.propertySourceId]?.label
    const userId = property?.value?.sourceUserId
    return (
      <Box sx={reasoningSx}>
        <Typography className="reasoning-text">
          {userId ? 'Manually edited' : property.value.reasoning}
        </Typography>

        <Row className="reasoning-info">
          {userId ? (
            <WorkspaceMemberChip userId={userId} />
          ) : (
            <>
              {icon && label && (
                <Chip
                  icon={React.createElement(icon, { size: 12 })}
                  label={label}
                  size="small"
                  variant="filled"
                  className="source-type-chip"
                  color="primary"
                />
              )}
            </>
          )}
          <Typography className="reasoning-date">
            {dayjs(property.value.updatedAt).format('MMM D, YYYY, h:mm a')}
          </Typography>
        </Row>
      </Box>
    )
  }
  return null
}

const PropertyEditMenu = ({
  propertyDefinition,
  currentValue,
  objectId,
  updateObjectProperty,
  onClose,
  anchorEl,
}: {
  propertyDefinition: ObjectPropertyDefinition
  currentValue: string | boolean
  objectId: string
  updateObjectProperty: ({ propertyDefinitionId, value, propertyType }) => void
  onClose: (_) => void
  anchorEl: HTMLElement | null
}) => {
  const open = Boolean(anchorEl)
  const [manualValue, setManualValue] = useState(currentValue?.value)
  const hasChanged = useRef(false)

  if (
    currentValue?.value !== undefined &&
    manualValue !== currentValue.value &&
    !hasChanged.current
  ) {
    setManualValue(currentValue.value)
  }

  const handleSave = useCallback(
    async (_) => {
      const input = {
        propertyDefinitionId: propertyDefinition.id,
        value: { value: manualValue },
        propertyType: propertyDefinition.propertyTypeId,
      }
      await updateObjectProperty(input)
    },
    [updateObjectProperty, propertyDefinition, manualValue]
  )

  const handleBooleanChange = useCallback(
    async (event) => {
      const newValue = event.target.checked
      setManualValue(newValue)
      hasChanged.current = true
      await updateObjectProperty({
        propertyDefinitionId: propertyDefinition.id,
        value: { value: newValue },
        propertyType: PropertyTypes.Boolean,
      })
    },
    [propertyDefinition.id, updateObjectProperty]
  )

  const isCustomProperty =
    propertyDefinition.id && isUUID(propertyDefinition.id)

  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          onClose(e)
        }}
        MenuListProps={{
          sx: getMenuSx(propertyDefinition.propertyTypeId),
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {propertyDefinition.propertyTypeId === PropertyTypes.Boolean ? (
          <MenuItem>
            <Tooltip
              title={propertyDefinition.description || propertyDefinition.name}
              arrow={true}
              placement="left"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(manualValue)}
                    onChange={handleBooleanChange}
                    size="small"
                    sx={{
                      padding: '4px',
                      '&.MuiCheckbox-root': {
                        color: 'text.secondary',
                      },
                    }}
                  />
                }
                label={propertyDefinition.name}
              />
            </Tooltip>
          </MenuItem>
        ) : propertyDefinition.propertyTypeId === PropertyTypes.Phone ? (
          <MenuItem
            sx={{
              minWidth: '272px',
              '& .PhoneInputInput': {
                border: 'none',
                '&:focus': {
                  border: 'none',
                },
                fontSize: '12px',
                fontFamily: (theme) => theme.typography.fontFamily,
                fontWeight: 500,
                letterSpacing: '-0.22px',
                p: 1,
              },
            }}
          >
            <PhoneInput
              international
              defaultCountry="US"
              value={(manualValue as string) || ''}
              onChange={(value: string | undefined) => {
                if (value === undefined || value === '') {
                  setManualValue('')
                } else {
                  setManualValue(value)
                }
                hasChanged.current = true
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  (!manualValue || isPossiblePhoneNumber(manualValue as string))
                ) {
                  handleSave(e)
                }
              }}
            />
          </MenuItem>
        ) : propertyDefinition.propertyTypeId === PropertyTypes.DateTime ? (
          <MenuItem>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dateLibInstance={dayjs}
            >
              <DateCalendar
                value={dayjs(manualValue as string)}
                onChange={(newValue) => {
                  setManualValue(newValue.toDate().toISOString())
                  hasChanged.current = true
                }}
                timezone="system"
              />
            </LocalizationProvider>
          </MenuItem>
        ) : (
          <MenuItem>
            <TextField
              label={propertyDefinition.name}
              value={manualValue || ''}
              onChange={(e) => {
                e.stopPropagation()
                setManualValue(e.target.value)
                hasChanged.current = true
              }}
              onKeyDown={(e) => {
                e.stopPropagation()
                if (e.key === 'Enter') {
                  handleSave(e)
                }
              }}
              size="small"
              fullWidth={true}
              sx={{ mt: '4px' }}
              multiline={true}
              maxRows={4}
            />
          </MenuItem>
        )}
        {hasChanged.current && (
          <Tooltip
            title={
              propertyDefinition.propertyTypeId === PropertyTypes.Phone &&
              !isPossiblePhoneNumber(manualValue as string)
                ? 'Please enter a valid phone number'
                : 'Your edits will take precedence over AI and Web research values'
            }
            arrow={true}
            placement="left"
          >
            <ListItemButton
              disabled={
                !hasChanged.current ||
                (propertyDefinition.propertyTypeId === PropertyTypes.Phone &&
                  !isPossiblePhoneNumber(manualValue as string))
              }
              onClick={handleSave}
            >
              <ListItemIcon>
                <IconCheck size={16} />
              </ListItemIcon>
              <ListItemText
                primary="Save"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
        <PropertyReasoningTeaser value={currentValue} />

        {isCustomProperty && (
          <PropertySettingsRow
            propertyDefinition={propertyDefinition}
            objectId={objectId}
            onClose={onClose}
          />
        )}
      </Menu>
    </Box>
  )
}

const PropertyOptionMenu = ({
  propertyValue,
  onSelect,
  objectId,
  propertyDefinition,
  onClose,
  anchorEl,
}: {
  propertyValue: any
  onSelect: (optionId: string) => void
  objectId: string
  propertyDefinition: any
  onClose: (_) => void
  anchorEl: HTMLElement | null
}) => {
  const open = Boolean(anchorEl)

  const safeSelectedOptionIds = useMemo(() => {
    if (!propertyValue) {
      return []
    }
    if (Array.isArray(propertyValue.value)) {
      try {
        const selected = Array.from(
          new Set<string>(
            (propertyValue?.value || []).map((option) =>
              option.value ? option.id : null
            )
          )
        ).filter(Boolean)

        logger.dev('safeSelectedOptionIds', {
          propertyValue,
          selected,
        })

        return selected
      } catch (e) {
        logger.warn('Error parsing propertyValue', { e, propertyValue })
      }
    } else if (typeof propertyValue.value === 'string') {
      return [propertyValue.value]
    }

    return []
  }, [propertyValue])

  const options = Array.isArray(propertyDefinition.options)
    ? propertyDefinition.options
    : []

  const containerSx = {}
  return (
    <Box sx={containerSx}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          onClose(e)
        }}
        className="custom-property-option-menu"
        MenuListProps={{
          disablePadding: true,
          sx: getMenuSx(propertyDefinition.propertyTypeId),
        }}
      >
        {options.map((option) => (
          <ListItemButton
            key={option.id}
            selected={(safeSelectedOptionIds || []).includes(option.id)}
            onClick={() => {
              logger.dev('option clicked', {
                option,
                safeSelectedOptionIds,
                propertyValue,
              })
              onSelect(option.id)
            }}
          >
            <ListItemIcon>
              {safeSelectedOptionIds.includes(option.id) ? (
                <IconSquareFilled size={16} />
              ) : (
                <IconSquare size={16} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={option.name}
              primaryTypographyProps={menuItemPrimaryTypographyProps}
            />
          </ListItemButton>
        ))}

        <PropertyReasoningTeaser value={propertyValue} />
        <PropertySettingsRow
          propertyDefinition={propertyDefinition}
          objectId={objectId}
          onClose={onClose}
        />
      </Menu>
    </Box>
  )
}

const PropertyEdit = ({
  propertyDefinition,
  objectId,
  objectType,
  workspaceId,
  value,
  justify = 'flex-end',
  onUpdate,
  onClose,
  dispatch = (_) => {},
  targetElementId = null,
  showEditButton = false,
}) => {
  const { updateObject } = useObjects()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [updateObjectPropertyGql] = useMutation(UPDATE_OBJECT_PROPERTY)

  useEffect(() => {
    if (targetElementId && !showEditButton) {
      const element = document.getElementById(targetElementId)
      if (element) {
        setAnchorEl(element)
      }
    }
  }, [targetElementId, showEditButton])

  const handleMenuOpen = useCallback(
    (event) => {
      const target = targetElementId
        ? document.getElementById(targetElementId)
        : event.currentTarget
      setAnchorEl(target)
      onClose(null)
    },
    [onClose, targetElementId]
  )

  const handleMenuClose = useCallback(
    (e) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      setAnchorEl(null)
      onClose(e)
    },
    [onClose]
  )

  const handleUpdateObjectProperty = useCallback(
    async ({ propertyDefinitionId, optionId = null, value, propertyType }) => {
      logger.dev('handleUpdateObjectProperty', {
        propertyDefinitionId,
        optionId,
        value,
        propertyType,
      })

      // Optimistically update UI
      onUpdate(value)
      dispatch({
        type: PROPERTY_ACTIONS.UPDATE_PROPERTY,
        payload: value,
      })

      try {
        await updateObjectPropertyGql({
          variables: {
            input: {
              workspaceId,
              objectType,
              objectId,
              value,
              propertyDefinitionId,
              propertyType,
            },
          },
        })

        await updateObject({
          objectId,
          objectType,
          propertyId: propertyDefinitionId,
          propertyValue: value,
        })

        dispatch({
          type: PROPERTY_ACTIONS.UPDATE_PROPERTY_SUCCESS,
          payload: { key: `${propertyDefinitionId}-${optionId}` },
        })
      } catch (error) {
        logger.error('Failed to update property:', error)

        // Revert the optimistic update
        dispatch({
          type: PROPERTY_ACTIONS.UPDATE_PROPERTY_ERROR,
          payload: { propertyDefinitionId, optionId, value },
        })
      }
    },
    [
      workspaceId,
      objectType,
      objectId,
      updateObjectPropertyGql,
      dispatch,
      onUpdate,
      updateObject,
    ]
  )

  const handleOptionToggle = useCallback(
    (optionId) => {
      let newValues = value?.value || []
      const trueOptionIds = Array.from(
        new Set(
          (newValues || [])
            .map((option) => (option?.value === true ? option.id : null))
            .filter(Boolean)
        )
      )
      if (propertyDefinition.propertyTypeId === PropertyTypes.MultiPicklist) {
        if (trueOptionIds.includes(optionId)) {
          const existingOptions = newValues.filter(
            (option) => option.id !== optionId
          )

          newValues = [...existingOptions, { id: optionId, value: false }]
        } else {
          newValues = [...newValues, { id: optionId, value: true }]
        }
      } else if (
        [PropertyTypes.Combobox, PropertyTypes.Picklist].includes(
          propertyDefinition.propertyTypeId
        )
      ) {
        if (trueOptionIds.includes(optionId) && trueOptionIds.length === 1) {
          newValues = []
        } else {
          newValues = [{ id: optionId, value: true }]
        }
      }
      const input = {
        propertyDefinitionId: propertyDefinition.id,
        optionId,
        value: { value: newValues },
        propertyType: propertyDefinition.propertyTypeId,
      }
      handleUpdateObjectProperty(input)
    },
    [handleUpdateObjectProperty, propertyDefinition, value]
  )

  const component = null
  const propertyType = propertyDefinition.propertyTypeId

  const propertyHasOptions = hasOptions(propertyType)

  const containerSx = {
    width: targetElementId ? '0px' : '100%',
    justifyContent: justify,
    '& .MuiTypography-root': {
      fontSize: '12px',
      color: 'text.secondary',
      fontWeight: 400,
      letterSpacing: '-0.22px',
    },
  }

  return (
    <Row
      sx={containerSx}
      onClick={(e) => e.stopPropagation()}
    >
      {showEditButton && (
        <IconButton onClick={(e) => handleMenuOpen(e)}>
          <IconPencil size={14} />
        </IconButton>
      )}
      {component ? (
        component
      ) : propertyHasOptions ? (
        <PropertyOptionMenu
          propertyDefinition={propertyDefinition}
          objectId={objectId}
          propertyValue={value}
          onSelect={handleOptionToggle}
          onClose={handleMenuClose}
          anchorEl={anchorEl}
        />
      ) : (
        <PropertyEditMenu
          propertyDefinition={propertyDefinition}
          objectId={objectId}
          onClose={handleMenuClose}
          anchorEl={anchorEl}
          currentValue={value}
          updateObjectProperty={handleUpdateObjectProperty}
        />
      )}
    </Row>
  )
}

export default PropertyEdit
