import { useCallback, useContext, useMemo, useState } from 'react'

import {
  Box,
  Chip,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Tooltip,
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import {
  IconCircle,
  IconColumns3,
  IconSearch,
  IconSparkles,
} from '@tabler/icons-react'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes, type NativeObjectType } from 'src/lib/objects'

import PropertyTypeIcon from './PropertyTypeIcon'
import useViews from './useViews'

const ColumnSelectorChip = () => {
  const { setSidebarObject, selectedWorkspace } = useContext(DayContext)
  const { objectType } = useViews()
  const apiRef = useGridApiContext()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const columns = apiRef?.current?.getAllColumns()

  const columnsVisible = apiRef?.current?.getVisibleColumns()
  const visibleColumnFields = columnsVisible?.map((column) => column.field)

  const columnsToShow = useMemo(() => {
    let columnsToFilter = columns
    if (search) {
      columnsToFilter = columnsToFilter?.filter((column) =>
        column.headerName.toLowerCase().includes(search.toLowerCase())
      )
    }
    return columnsToFilter
  }, [columns, search])

  const handleCreateColumn = useCallback(() => {
    setSidebarObject({
      objectType: NativeObjectTypes.PropertyDefinition,
      mode: 'create',
      objectId: 'new',
      workspaceId: selectedWorkspace,
      properties: {
        name: '',
        description: '',
        objectTypeId: objectType || '',
        aiManaged: true,
        useWeb: false,
        options: [],
      },
    })
    setOpen(false)
    setAnchorEl(null)
  }, [setSidebarObject, selectedWorkspace, objectType])

  const handleColumnClick = useCallback(
    (column) => {
      const isVisible = apiRef?.current?.getVisibleColumns().includes(column)
      apiRef?.current?.setColumnVisibility(column.field, !isVisible)
    },
    [apiRef]
  )

  const containerSx = {
    '& .search-container': {
      boxShadow: (theme) => theme.shadows[1],
      '& .MuiInputBase-root': {
        px: '8px',
        py: '4px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: '0px',
      },
    },
    '& .MuiMenuItem-root': {
      px: '8px',

      '& .MuiListItemIcon-root': {
        minWidth: '24px',
      },
    },

    '& .MuiMenu-list': {
      py: 0,
      width: '200px',

      '& .MuiList-root.column-list': {
        background: (theme) => theme.palette.background.default,
        maxHeight: '300px',
        overflowY: 'auto',
        borderBottom: (theme) => `1px solid ${theme.palette.action.hover}`,

        '& .MuiListItemText-root': {
          '& .MuiListItemText-primary': {
            fontSize: '11px',
            letterSpacing: '-0.3px',
            fontWeight: 600,
            opacity: 0.9,
          },
        },
        '& .MuiListItemIcon-root': {
          '& .tabler-icon': {
            height: '12px',
            color: (theme) => theme.palette.text.primary,
            opacity: 0.9,
          },
        },
        '& .column-name': {
          '&.hidden-column': {
            '& .MuiListItemText-primary': {
              fontWeight: 400,
              opacity: 0.9,
            },
          },
        },
      },
      '& .MuiList-root.column-list-actions': {
        py: 0,
        boxShadow: (theme) => theme.shadows[2],
        '& .MuiMenuItem-root': {
          fontWeight: 600,
          fontSize: '12px',
          letterSpacing: '-0.3px',
          py: '10px',

          '& .MuiListItemIcon-root': {
            opacity: 1,
            color: (theme) => theme.palette.text.primary,
          },
        },
      },
    },
  }

  return (
    <>
      <Tooltip
        title="Click to show/hide properties (columns)"
        arrow={true}
        placement="top"
      >
        <Box>
          <Chip
            size="small"
            variant="outlined"
            className="columns"
            icon={
              <IconColumns3
                size={8}
                stroke={2.5}
              />
            }
            onClick={(e) => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
              setOpen(true)
            }}
            label={`${apiRef?.current?.getVisibleColumns().length} Properties`}
            deleteIcon={<IconCircle style={{ opacity: 0 }} />}
            onDelete={() => {}}
          />
        </Box>
      </Tooltip>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            sx: containerSx,
          },
        }}
      >
        <Box className="search-container">
          <TextField
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch size={16} />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={(e) => {
              e.stopPropagation()
            }}
            fullWidth={true}
            variant="standard"
          />
        </Box>
        <MenuList className="column-list">
          {columnsToShow?.map((column) => (
            <MenuItem
              key={`column-${column.field}`}
              onClick={() => handleColumnClick(column)}
            >
              <ListItemIcon>
                <PropertyTypeIcon
                  objectType={objectType as NativeObjectType}
                  propertyId={column.field}
                  bold={visibleColumnFields.includes(column.field)}
                  size={24}
                />
              </ListItemIcon>
              <ListItemText
                className={`column-name ${
                  !visibleColumnFields.includes(column.field)
                    ? 'hidden-column'
                    : ''
                }`}
                primary={column.headerName}
              />
            </MenuItem>
          ))}
        </MenuList>
        <MenuList className="column-list-actions">
          <MenuItem
            divider={true}
            onClick={handleCreateColumn}
          >
            <ListItemIcon>
              <IconSparkles
                size={16}
                stroke={2.5}
              />
            </ListItemIcon>
            Create new property
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

export default ColumnSelectorChip
