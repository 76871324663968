import { useCallback, useMemo, useReducer } from 'react'

import { useZero } from '@rocicorp/zero/react'
import { useQuery } from '@rocicorp/zero/react'
import type {
  DayObject,
  DayProperty,
  ObjectPropertyDefinition,
} from 'types/graphql'

import { logger } from 'src/lib/logger'
import type { Schema } from 'src/zero/schema'

import navigationReducer, {
  NavigationActions,
  type NavigationUserAction,
} from './navigation'
import ObjectSidebar from './ObjectSidebar'
import { sidebarReducer } from './reducer'
import type { SidebarMode } from './SidebarContext'
import SidebarContext, { SidebarActions } from './SidebarContext'

const SidebarProvider = ({
  children,
  workspaceId,
}: {
  children: React.ReactNode
  workspaceId: string
}) => {
  const z = useZero<Schema>()

  const [propertyDefinitionsResultsZero] = useQuery(
    z.query.objectPropertyDefinition
      .where(({ and, cmp }) => and(cmp('workspaceId', workspaceId)))
      .related('objectPropertyDefinitionOptions')
  )

  const propertyDefinitions = useMemo(() => {
    const output = []
    const propDefsRaw =
      propertyDefinitionsResultsZero as unknown as ObjectPropertyDefinition[]
    for (const propDef of propDefsRaw) {
      if (propDef) {
        const entry = {
          ...propDef,
          options: propDef.objectPropertyDefinitionOptions,
        }
        delete entry.objectPropertyDefinitionOptions
        output.push(entry)
      }
    }
    return output
  }, [propertyDefinitionsResultsZero])

  const [state, dispatch] = useReducer(sidebarReducer, {
    mode: 'view',
    object: null,
  })

  const [navigationState, navigationDispatch] = useReducer(navigationReducer, {
    history: [],
    currentIndex: 0,
  })

  const handleSetObject = useCallback(
    ({ object, mode }: { object: Partial<DayObject>; mode?: SidebarMode }) => {
      logger.dev('SET_OBJECT', {
        object,
        mode,
      })
      dispatch({
        type: SidebarActions.SET_OBJECT,
        payload: { object, mode },
      })
    },
    []
  )

  const handleUpdateProperty = useCallback((updatedProperty: DayProperty) => {
    logger.dev('Updating property', { updatedProperty })
    dispatch({
      type: SidebarActions.UPDATE_PROPERTY,
      payload: updatedProperty,
    })
  }, [])

  const handleSetMode = useCallback((mode: SidebarMode) => {
    dispatch({
      type: SidebarActions.SET_MODE,
      payload: mode,
    })
  }, [])

  const handleNavigateForward: NavigationUserAction = useMemo(() => {
    return {
      run: () => {
        navigationDispatch({
          type: NavigationActions.NAVIGATE_FORWARD,
        })
      },
      enabled:
        navigationState.currentIndex < navigationState.history.length - 1,
    }
  }, [navigationState.history.length, navigationState.currentIndex])

  const handleNavigateBack: NavigationUserAction = useMemo(() => {
    return {
      run: () => {
        navigationDispatch({
          type: NavigationActions.NAVIGATE_BACK,
        })
      },
      enabled: navigationState.currentIndex > 0,
    }
  }, [navigationState.currentIndex])

  const handleCloseSidebar = useCallback(() => {
    dispatch({
      type: SidebarActions.RESET,
      payload: null,
    })
  }, [])

  const handleExamineProperty = useCallback((propertyId: string) => {
    dispatch({
      type: SidebarActions.EXAMINE_PROPERTY,
      payload: propertyId,
    })
  }, [])

  const value = useMemo(() => {
    return {
      forward: handleNavigateForward,
      back: handleNavigateBack,
      object: state.object,
      mode: state.mode,
      workspaceId,
      setSidebarObject: handleSetObject,
      setMode: handleSetMode,
      closeSidebar: handleCloseSidebar,
      updateProperty: handleUpdateProperty,
      examineProperty: handleExamineProperty,
      propertyToExamine: state.propertyToExamine,
      propertyDefinitions,
    }
  }, [
    handleNavigateForward,
    handleNavigateBack,
    workspaceId,
    handleSetObject,
    handleSetMode,
    state.mode,
    state.object,
    handleCloseSidebar,
    handleUpdateProperty,
    handleExamineProperty,
    state.propertyToExamine,
    propertyDefinitions,
  ])

  return (
    <SidebarContext.Provider value={value}>
      {children}
      {state?.object && <ObjectSidebar />}
    </SidebarContext.Provider>
  )
}

export default SidebarProvider
