export const UPDATE_PARALLEL_TASK = gql`
  mutation UpdateParallelTask {
    adminUpdateParallelOrgEnrichmentTask
  }
`

export const RUN_PARALLEL_ORG_ENRICH = gql`
  mutation RunParallelTask($domain: String!) {
    adminRunParallelOrgEnrichmentTask(domain: $domain)
  }
`

export const ENRICH_ORGANIZATION_ASYNC_MUTATION = gql`
  mutation EnrichOrganizationAsyncMutation(
    $domain: String!
    $forceMigrate: Boolean!
  ) {
    organizationEnrichAsync(domain: $domain, forceMigrate: $forceMigrate)
  }
`

export const MODEL_ORGANIZATION_ASYNC_MUTATION = gql`
  mutation ModelOrganizationAsyncMutation($domain: String!) {
    organizationModelAsync(domain: $domain)
  }
`

export const UPDATE_GLOBAL_ORGANIZATION_MUTATION = gql`
  mutation UpdateGlobalOrganizationMutation($domain: String!, $data: JSON!) {
    updateGlobalOrganization(domain: $domain, data: $data)
  }
`

export const REFRESH_ORG_OVERVIEW = gql`
  mutation RefreshOrgOverview($workspaceId: String!, $domain: String!) {
    refreshOverview(workspaceId: $workspaceId, domain: $domain)
  }
`

export const ENSURE_ORGANIZATION_LOGO_ASYNC = gql`
  mutation EnsureOrganizationLogoAsync($domain: String!) {
    ensureOrganizationLogoAsync(domain: $domain)
  }
`
