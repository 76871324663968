import { useCallback, useRef, useState, useEffect, useMemo } from 'react'

import {
  Checkbox,
  IconButton,
  Menu,
  TextField,
  ListItem,
  FormControlLabel,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tooltip,
  Box,
} from '@mui/material'
import {
  IconChevronDown,
  IconChevronLeft,
  IconProgressCheck,
} from '@tabler/icons-react'
import type { View } from 'types/graphql'

import Row from '../Row/Row'

import useViews from './useViews'
import ViewDelete from './ViewDelete'
import ViewRemovePin from './ViewRemovePin'

const ViewTab = ({
  view,
  currentView,
  setCurrentView,
}: {
  view: View
  currentView: View
  setCurrentView: (_) => void
}) => {
  const { updateView, saving, loading } = useViews()

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)
  const [title, setViewTitle] = useState(currentView?.title || '')
  const [description, setDescription] = useState(currentView?.description || '')
  const [showDescription, setShowDescription] = useState(true)
  const [shareWithWorkspace, setShareWithWorkspace] = useState(
    currentView?.shareWithWorkspace || false
  )

  // Update local state when currentView changes
  useEffect(() => {
    if (currentView) {
      setViewTitle(currentView.title || '')
      setDescription(currentView.description || '')
      setShareWithWorkspace(currentView.shareWithWorkspace || false)
    }
  }, [currentView])

  const handleUpdate = useCallback(async () => {
    if (!currentView?.id || !title.trim()) return

    try {
      await updateView({
        id: currentView.id,
        input: {
          title: title.trim(),
          description: description.trim(),
          shareWithWorkspace,
        },
      })
      setCreateMenuEl(null)
    } catch (error) {
      console.error('Error updating view:', error)
    }
  }, [updateView, currentView, title, description, shareWithWorkspace])

  const titleRef = useRef<HTMLInputElement>(null)

  const handleEdit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
      setTimeout(() => {
        titleRef.current?.focus()
      }, 100)
    },
    []
  )

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setViewTitle(e.target.value)
    },
    []
  )

  const handleTitleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        handleUpdate()
      }
    },
    [handleUpdate]
  )

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      setDescription(e.target.value)
    },
    []
  )

  const handleDescriptionKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
    },
    []
  )

  const handleDescriptionToggle = useCallback(() => {
    setShowDescription((prev) => !prev)
  }, [])

  const handleWorkspaceShareChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShareWithWorkspace(e.target.checked)
    },
    []
  )

  const isSelected = useMemo(() => {
    return currentView?.id === view.id
  }, [currentView, view])

  // Only render if we have a current view
  if (!currentView) return null

  return (
    <>
      <Tooltip
        title={
          isSelected
            ? `(click to edit) ${view.description || ''}`.trim()
            : `${view.description || ''}`.trim()
        }
        arrow={true}
        placement="top"
      >
        <Box>
          <Tab
            className={`view-tab ${isSelected ? 'Mui-selected' : ''}`}
            key={view.id}
            label={
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography>{view.title}</Typography>
                {currentView?.id === view.id && (
                  <Row sx={{ ml: '6px' }}>
                    <ViewRemovePin />
                  </Row>
                )}
              </Row>
            }
            value={view.id}
            disableFocusRipple={true}
            disabled={loading}
            onClick={(e) => {
              if (isSelected) {
                // open edit menu
                handleEdit(e as unknown as React.MouseEvent<HTMLButtonElement>)
              } else {
                setCurrentView(view.id)
              }
            }}
          />
        </Box>
      </Tooltip>
      <Menu
        anchorEl={createMenuEl}
        open={!!createMenuEl}
        onClose={() => setCreateMenuEl(null)}
        sx={{ '& .MuiList-root': { pb: 0 } }}
      >
        <ListItem>
          <Row
            sx={{ width: '320px' }}
            gap={1}
          >
            <TextField
              inputRef={titleRef}
              label="Title"
              value={title}
              onChange={handleTitleChange}
              onKeyDown={handleTitleKeyDown}
              fullWidth={true}
              size="small"
            />
            <IconButton
              onClick={handleDescriptionToggle}
              sx={{
                p: '4px',
                borderRadius: '3px',
              }}
            >
              {!showDescription ? (
                <IconChevronLeft size={20} />
              ) : (
                <IconChevronDown size={20} />
              )}
            </IconButton>
          </Row>
        </ListItem>

        {showDescription && (
          <ListItem>
            <TextField
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              onKeyDown={handleDescriptionKeyDown}
              fullWidth={true}
              size="small"
              multiline={true}
              rows={3}
              autoComplete="off"
            />
          </ListItem>
        )}
        {showDescription && (
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithWorkspace}
                  onChange={handleWorkspaceShareChange}
                  size="small"
                  color="secondary"
                  sx={{
                    opacity: 0.6,
                    '&.Mui-checked': { opacity: 1 },
                  }}
                />
              }
              label="Share with workspace"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                  letterSpacing: '-0.22px',
                },
              }}
            />
          </ListItem>
        )}

        <ViewDelete />
        <ListItemButton
          onClick={handleUpdate}
          disabled={!title.trim() || saving}
          sx={{
            background: (theme) => theme.palette.secondary.main,
            '& .MuiTypography-root, & svg': {
              color: (theme) => theme.palette.secondary.contrastText,
            },
            '&:hover': {
              background: (theme) => theme.palette.secondary.dark,
            },
            '& .MuiListItemIcon-root': {
              ml: '-2px',
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              background: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <IconProgressCheck size={20} />
          </ListItemIcon>
          <ListItemText
            primary={saving ? 'Updating...' : 'Update'}
            primaryTypographyProps={{
              fontSize: '12px',
              letterSpacing: '-0.22px',
              fontWeight: 600,
            }}
          />
        </ListItemButton>
      </Menu>
    </>
  )
}

export default ViewTab
