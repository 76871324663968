import { createContext } from 'react'

import type {
  DayObjectSearchEntry,
  ObjectPropertyDefinition,
} from 'types/graphql'

import type { NativeObjectType } from 'src/lib/objects'

export type ObjectsContextType = {
  workspaceId: string
  syncState: SyncState | null
  resetDayObjects: () => Promise<void>
  getObjects: ({
    objectType,
    objectIds,
  }: {
    objectType: NativeObjectType
    objectIds: string[]
  }) => Promise<DayObjectSearchEntry[]>
  updateObject: ({
    objectId,
    objectType,
    propertyId,
    propertyValue,
  }: {
    objectId: string
    objectType: NativeObjectType
    propertyId: string
    propertyValue: any
  }) => Promise<void>
  resyncObject: (
    objectId: string,
    objectType: NativeObjectType
  ) => Promise<void>
  propertyDefinitions: {
    [key in NativeObjectType]: {
      [key: string]: ObjectPropertyDefinition
    }
  }
}

export type SyncStateObjectType = {
  objectType: NativeObjectType
  objectsNeedingSync: number
  currentCount: number
  needsMoreIds: boolean
  updatedAt: Date | null
}

export type SyncState = {
  [key in NativeObjectType]: SyncStateObjectType
}

const ObjectsContext = createContext<ObjectsContextType | null>(null)

export default ObjectsContext
