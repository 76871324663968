import { useState } from 'react'

import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Avatar } from '@mui/material'
import { IconButton } from '@mui/material'
import {
  IconBolt,
  IconBubbleText,
  IconChevronLeft,
  IconSparkles,
} from '@tabler/icons-react'
import type {
  DayObject,
  DayProperty,
  ObjectPropertyDefinition,
} from 'types/graphql'

import AiFeature from 'src/components/AiFeature/AiFeature'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import PropertyEdit from 'src/components/Properties/PropertyEdit'
import Row from 'src/components/Row/Row'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, type NativeObjectType } from 'src/lib/objects'
import { buildGqlObject, searchMetadataBuilders } from 'src/lib/Objects/build'
import {
  StandardProperties,
  StandardPropertyAsObjectPropertyDefinition,
  StandardPropertyEditPolicy,
  StandardPropertyTablePolicy,
} from 'src/lib/Properties/properties'

import Relationships from './Relationships'
import { SidebarMode } from './SidebarContext'
import useSidebar from './useSidebar'

const SidebarHeader = () => {
  const { object, setSidebarObject, closeSidebar } = useSidebar()
  const metadata = searchMetadataBuilders[object.objectType](object)
  const gqlObject = buildGqlObject(object)
  logger.dev(`gqlObject: ${gqlObject}`)
  return (
    metadata && (
      <Row className="header-row">
        <Row
          gap={2}
          sx={{ pt: 2 }}
        >
          {object.objectType === NativeObjectTypes.Organization ? (
            <DomainAvatar domain={object.objectId} />
          ) : object.objectType === NativeObjectTypes.Person ? (
            <ContactAvatar email={object.objectId} />
          ) : (
            <Avatar
              className="main-avatar"
              src={metadata.photoUrl}
            />
          )}
          <Box>
            <Row className="object-title-row">
              <Row gap={1}>
                <Typography variant="h2">{metadata.label}</Typography>
                {object.objectType === NativeObjectTypes.Organization && (
                  <OrganizationSocialLinks
                    organization={gqlObject}
                    iconSize={10}
                  />
                )}
              </Row>

              <Row className="action-buttons">
                <AiFeature
                  workspaceId={object.workspaceId}
                  featureKey={object.objectType}
                  objectId={object.objectId}
                  variant="outlined"
                  size={16}
                  lastUpdated={new Date(object?.updatedAt)}
                  sx={{ p: '6px' }}
                />
                <ThreadCreateButton
                  workspaceId={object.workspaceId}
                  sourceObject={{
                    objectType: object.objectType,
                    objectId: object.objectId,
                    properties: object,
                  }}
                  otherObjects={[]}
                  onThreadCreated={() => {
                    closeSidebar()
                  }}
                  variant="icon"
                  disableElevation={false}
                />
                <Tooltip title={`Add context to ${metadata.label}`}>
                  <IconButton
                    onClick={() => {
                      setSidebarObject({
                        mode: SidebarMode.CREATE,
                        object: {
                          objectType: NativeObjectTypes.UserContext,
                        },
                      })
                    }}
                    sx={actionButtonStyle}
                    className={'disable-elevation'}
                  >
                    <IconBubbleText size={14} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Create an action to do for ${metadata.label}`}>
                  <IconButton
                    onClick={() => {
                      setSidebarObject({
                        mode: SidebarMode.CREATE,
                        object: {
                          objectType: NativeObjectTypes.Action,
                        },
                      })
                    }}
                    sx={actionButtonStyle}
                    className={'disable-elevation'}
                  >
                    <IconBolt size={14} />
                  </IconButton>
                </Tooltip>
              </Row>
            </Row>
            <Typography className="description">
              {metadata.description}
            </Typography>
          </Box>
        </Row>
      </Row>
    )
  )
}

const StandardPropertyRenderer = ({
  propertyId,
  property,
}: {
  propertyId: string
  property: DayProperty
}) => {
  const { object, updateProperty, workspaceId, examineProperty } = useSidebar()
  const standardPropDef = StandardProperties?.[object?.objectType]?.[propertyId]

  const propertyDefinition = StandardPropertyAsObjectPropertyDefinition({
    objectType: object?.objectType as NativeObjectType,
    propertyId: propertyId,
    workspaceId: object?.workspaceId,
  })

  if (!propertyDefinition) {
    logger.dev('Property definition missing', {
      objectType: object?.objectType,
      propertyId: property?.propertyId,
    })
    return null
  }

  return (
    object &&
    propertyDefinition &&
    standardPropDef && (
      <Row className="property-row">
        <Box className="property-edit-container">
          <Box className="property-edit-inner">
            {standardPropDef.editPolicy ===
              StandardPropertyEditPolicy.ALLOW && (
              <PropertyEdit
                propertyDefinition={propertyDefinition}
                objectId={object.objectId}
                objectType={object.objectType}
                workspaceId={workspaceId}
                value={property}
                justify="start"
                onUpdate={(updatedProperty) => {
                  updateProperty(updatedProperty)
                }}
                onClose={() => {}}
                showEditButton={true}
              />
            )}

            {standardPropDef.editPolicy ===
              StandardPropertyEditPolicy.CONTEXT_ONLY && (
              <IconButton
                onClick={() => {
                  examineProperty(propertyId)
                }}
              >
                <IconSparkles />
              </IconButton>
            )}
          </Box>
        </Box>
        <Row className="property-row-content">
          <Typography className="property-name">
            {propertyDefinition.name}
          </Typography>
          <Box className="property-value">
            <PropertyDisplay
              propertyDefinition={propertyDefinition}
              objectId={object.objectId}
              objectType={object.objectType as NativeObjectType}
              workspaceId={object.workspaceId}
              value={property}
              justify="flex-start"
            />
          </Box>
        </Row>
      </Row>
    )
  )
}

const SidebarStandardProperties = () => {
  const { object } = useSidebar()

  const [expanded, setExpanded] = useState(false)

  const defaultShownProperties = Object.entries(
    StandardProperties?.[object.objectType] || {}
  )
    .filter(
      ([_, property]) =>
        property.tablePolicy === StandardPropertyTablePolicy.DEFAULT
    )
    .map(([key]) => key)

  const secondaryShownProperties = Object.entries(
    StandardProperties?.[object.objectType] || {}
  )
    .filter(
      ([_, property]) =>
        property.tablePolicy === StandardPropertyTablePolicy.SHOW
    )
    .map(([key]) => key)

  return (
    <Box className="section">
      <Row className="section-header-row">
        <Typography className="section-title">About</Typography>
        <Button
          className="expand-collapse-button"
          onClick={() => setExpanded(!expanded)}
          size="small"
          endIcon={
            <IconChevronLeft
              size={16}
              className={`expand-arrow ${expanded ? 'expanded' : ''}`}
            />
          }
        >
          {expanded ? 'Fewer' : 'More'}
        </Button>
      </Row>
      <Box className="properties">
        {defaultShownProperties.map((key) => (
          <StandardPropertyRenderer
            key={key}
            propertyId={key}
            property={object.properties.standard[key]}
          />
        ))}
        {expanded && (
          <>
            {secondaryShownProperties.map((key) => (
              <StandardPropertyRenderer
                key={key}
                propertyId={key}
                property={object.properties.standard[key]}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

const SidebarCustomPropertyRenderer = ({
  propertyDefinitionId,
  propertyDefinitions,
  value,
  object,
  workspaceId,
  updateProperty,
}: {
  propertyDefinitionId: string
  propertyDefinitions: ObjectPropertyDefinition[]
  value: DayProperty
  object: DayObject
  workspaceId: string
  updateProperty: (property: DayProperty) => void
}) => {
  const propertyDefinition = propertyDefinitions.find(
    (propertyDefinition) => propertyDefinition.id === propertyDefinitionId
  )

  return propertyDefinition ? (
    <Row className="property-row">
      <Box className="property-edit-container">
        <Box className="property-edit-inner">
          <PropertyEdit
            propertyDefinition={propertyDefinition}
            objectId={object.objectId}
            objectType={object.objectType}
            workspaceId={workspaceId}
            value={value}
            justify="start"
            onUpdate={(updatedProperty) => {
              updateProperty(updatedProperty)
            }}
            onClose={() => {}}
            showEditButton={true}
          />
        </Box>
      </Box>
      <Row className="property-row-content">
        <Typography className="property-name">
          {propertyDefinition.name}
        </Typography>
        <Box className="property-value">
          <PropertyDisplay
            propertyDefinition={propertyDefinition}
            objectId={object.objectId}
            objectType={object.objectType as NativeObjectType}
            workspaceId={object.workspaceId}
            value={value}
            justify="flex-start"
          />
        </Box>
      </Row>
    </Row>
  ) : null
}

const SidebarCustomProperties = () => {
  const { object, updateProperty, workspaceId, propertyDefinitions } =
    useSidebar()

  return (
    <Box className="section">
      <Row className="section-header-row">
        <Typography className="section-title">Custom Properties</Typography>
      </Row>
      <Box className="properties">
        {Object.entries(object.properties.custom).map(([key, value]) => (
          <SidebarCustomPropertyRenderer
            key={key}
            propertyDefinitionId={key}
            propertyDefinitions={propertyDefinitions}
            value={value}
            object={object}
            workspaceId={workspaceId}
            updateProperty={updateProperty}
          />
        ))}
      </Box>
    </Box>
  )
}

const _SidebarRelationships = () => {
  const { object } = useSidebar()
  return [NativeObjectTypes.Organization].includes(
    object?.objectType as NativeObjectType
  ) ? (
    <Box className="section">
      <Relationships object={object} />
    </Box>
  ) : null
  // TODO: Add Person Relationships here
}

const SidebarModeView = () => {
  const { object } = useSidebar()

  return (
    object && (
      <Box className="sidebar-content-mode-view">
        <SidebarHeader />
        <Box className="sidebar-content-mode-view-inner">
          {/* <SidebarRelationships /> */}

          <SidebarStandardProperties />
          <SidebarCustomProperties />
        </Box>
      </Box>
    )
  )
}

export default SidebarModeView
