import { createContext, type RefObject } from 'react'

import type { GridState, GridInitialState } from '@mui/x-data-grid-premium'
import type { View, CreateViewInput } from 'types/graphql'

import type { NativeObjectType } from 'src/lib/objects'

// Define specific action types with proper typing
export const VIEW_ACTIONS = {
  SET_VIEWS: 'SET_VIEWS',
  ADD_VIEW: 'ADD_VIEW',
  UPDATE_VIEW: 'UPDATE_VIEW',
  REMOVE_VIEW: 'REMOVE_VIEW',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  TOGGLE_PIN: 'TOGGLE_PIN',
  SET_SAVING_STATUS: 'SET_SAVING_STATUS',
  APPLY_GRID_STATE: 'APPLY_GRID_STATE',
  OPTIMISTIC_UPDATE: 'OPTIMISTIC_UPDATE',
  REVERT_OPTIMISTIC: 'REVERT_OPTIMISTIC',
} as const

// Pending operation tracking for optimistic updates
interface PendingOperation {
  previousState: ViewsState
  changes: Record<string, any>
}

// Main state interface
export interface ViewsState {
  views: View[]
  pinnedViews: View[]
  currentView: View | null
  saving: boolean
  error: string | null
  pendingOperations: Record<string, PendingOperation>
  lastSyncedGridState: GridState | null
  optimisticUpdates: Array<string>
  objectType: NativeObjectType | null
}

// Union type of all possible action types with properly typed payloads
export type ViewsAction =
  | { type: typeof VIEW_ACTIONS.SET_VIEWS; payload: View[] }
  | { type: typeof VIEW_ACTIONS.ADD_VIEW; payload: View }
  | {
      type: typeof VIEW_ACTIONS.UPDATE_VIEW
      payload: { id: string; changes: Partial<View> }
    }
  | { type: typeof VIEW_ACTIONS.REMOVE_VIEW; payload: string }
  | { type: typeof VIEW_ACTIONS.SET_CURRENT_VIEW; payload: View | null }
  | {
      type: typeof VIEW_ACTIONS.TOGGLE_PIN
      payload: { id: string; pinned: boolean; position?: number }
    }
  | { type: typeof VIEW_ACTIONS.SET_SAVING_STATUS; payload: boolean }
  | { type: typeof VIEW_ACTIONS.APPLY_GRID_STATE; payload: GridInitialState }
  | {
      type: typeof VIEW_ACTIONS.OPTIMISTIC_UPDATE
      payload: {
        operationId: string
        changes: Record<string, any>
      }
    }
  | {
      type: typeof VIEW_ACTIONS.REVERT_OPTIMISTIC
      payload: {
        operationId: string
        error?: string
      }
    }

// Context value interface with state and actions for consumers
export interface ViewsContextValue {
  // State properties
  views: View[]
  pinnedViews: View[]
  currentView: View | null
  loading: boolean
  saving: boolean
  error: string | Error | null
  objectType: string | null
  // Action methods
  createView: (
    input: Partial<CreateViewInput>,
    options?: { useCurrentState?: boolean }
  ) => Promise<View | null>
  updateView: (params: { id: string; input: any }) => Promise<View | null>
  deleteView: (viewId: string) => Promise<boolean>
  shareView: (id: string, userId: string) => Promise<View | null>
  addPin: (id: string) => Promise<View | null>
  removePin: (id: string) => Promise<View | null>
  activateView: (view: View) => void
  refetchViews: () => Promise<any>
  getExternalGridState: () => GridInitialState | undefined
  expandedGroupsRef: RefObject<Set<string | number>>
  handleGroupExpansionChange: (newExpandedGroupIds: (string | number)[]) => void
  filterColumnRequested: {
    field: string
    anchorEl: HTMLElement
  } | null
  resetFilterColumnRequested: () => void
  // Direct dispatch access for advanced usage
  dispatch: React.Dispatch<ViewsAction>
}

// Create the context with undefined initial value
const ViewsContext = createContext<ViewsContextValue | undefined>(undefined)

export default ViewsContext
