import { useCallback, useContext, useMemo } from 'react'

import {
  Box,
  Button,
  Chip,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useGridApiContext, type GridSortItem } from '@mui/x-data-grid-premium'
import {
  IconArrowBigDown,
  IconArrowBigUp,
  IconDownload,
  IconPinFilled,
  IconX,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'
import { modernButtonContainer } from 'src/lib/style'

import Row from '../Row/Row'

import ColumnSelectorChip from './ColumnSelectorChip'
import { viewManagerContainerSx, displaySettingsSx } from './styles'
import ViewCreate from './ViewCreate'
import ViewFilters from './ViewFilters'
import ViewsContext from './ViewsContext'
import type { ViewsContextValue } from './ViewsContext'
import ViewTab from './ViewTab'

interface ViewManagerProps {
  showDisplaySettings: boolean
  setShowDisplaySettings: (_) => void
}

const ViewManager: React.FC<ViewManagerProps> = ({
  showDisplaySettings,
  setShowDisplaySettings,
}) => {
  const viewsContext = useContext(ViewsContext) as ViewsContextValue
  const apiGridRef = useGridApiContext()

  const {
    views,
    pinnedViews,
    currentView,
    loading,
    saving: _saving,
    activateView,
  } = viewsContext

  const apiRef = useGridApiContext()

  const handleViewChange = useCallback(
    (viewId: string | null) => {
      if (!viewId) return

      const view = views.find((v) => v.id === viewId)
      if (view) {
        activateView(view)
      }
    },
    [views, activateView]
  )

  const handleToggleSort = (field, remove = false) => {
    const currentSortModel = apiGridRef.current.getSortModel()
    const newSortModel = currentSortModel
      .map((sort) => {
        if (sort.field === field) {
          const newSort = remove ? null : sort.sort === 'asc' ? 'desc' : 'asc'
          return { ...sort, sort: newSort }
        }
        return sort
      })
      .filter((sort) => sort.sort !== null) as GridSortItem[]
    logger.dev('newSortModel', newSortModel)
    apiGridRef.current.setSortModel(newSortModel)
  }

  const handleTogglePin = (side: 'left' | 'right') => {
    apiGridRef.current.setPinnedColumns({
      ...pinnedColumns,
      [side]: [],
    })
  }

  const sortsCount = apiGridRef.current.exportState().sorting.sortModel.length
  const filtersCount =
    apiGridRef.current.exportState().filter.filterModel.items.length
  const groupingsCount =
    apiGridRef.current.exportState().rowGrouping.model.length

  const pinnedColumns = apiGridRef.current.getPinnedColumns() || {
    left: [],
    right: [],
  }
  const pinnedColumnsCount =
    (pinnedColumns?.left?.length ?? 0) + (pinnedColumns?.right?.length ?? 0)

  const modifiersState = useMemo(() => {
    const total =
      sortsCount + filtersCount + groupingsCount + pinnedColumnsCount + 1
    return {
      total,
      sorts: sortsCount,
      filters: filtersCount,
      groupings: groupingsCount,
      pinnedColumns: pinnedColumnsCount,
    }
  }, [sortsCount, filtersCount, groupingsCount, pinnedColumnsCount])

  return (
    <Box>
      <Row sx={viewManagerContainerSx}>
        <Row className="view-manager-tabs-container">
          <Row className="view-tab-inner">
            {pinnedViews.length > 0 ? (
              <Tabs
                value={0}
                visibleScrollbar={true}
                className="view-manager-tabs"
              >
                {pinnedViews.map((view) => (
                  <ViewTab
                    key={view.id}
                    view={view}
                    currentView={currentView}
                    setCurrentView={handleViewChange}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs value={loading ? 'loading' : 'all'}>
                <Tab
                  value={loading ? 'loading' : 'all'}
                  disabled={loading}
                  className="view-manager-tabs"
                  label={
                    <Row>
                      <Typography>{loading ? 'Loading...' : 'All'}</Typography>
                    </Row>
                  }
                />
              </Tabs>
            )}
            <Row className="view-create-row">
              <ViewCreate />
            </Row>
          </Row>

          <Row
            gap={1}
            className="view-tab-inner"
          >
            <Tooltip
              title="Export"
              arrow={true}
            >
              <IconButton className="export-button">
                <IconDownload
                  size={12}
                  stroke={2.5}
                />
              </IconButton>
            </Tooltip>

            <Box
              className="view-tab-inner"
              sx={{ ...modernButtonContainer }}
            >
              {!showDisplaySettings ? (
                <Tooltip
                  title={
                    showDisplaySettings
                      ? 'Hide display settings'
                      : 'Show display settings'
                  }
                  arrow={true}
                >
                  <Button
                    onClick={() => setShowDisplaySettings((prev) => !prev)}
                    size="small"
                    variant="outlined"
                    className="display-settings-button right flat"
                    startIcon={
                      <Row className="modifier-badge-row">
                        {modifiersState.sorts > 0 && (
                          <Box className="modifier-badge sorts" />
                        )}
                        {modifiersState.groupings > 0 && (
                          <Box className="modifier-badge groupings" />
                        )}
                        {modifiersState.filters > 0 && (
                          <Box className="modifier-badge filters" />
                        )}
                        {modifiersState.pinnedColumns > 0 && (
                          <Box className="modifier-badge pinned" />
                        )}
                        <Box className="modifier-badge columns" />
                        <Box className="modifiers-count">
                          <Typography>{modifiersState.total}</Typography>
                        </Box>
                      </Row>
                    }
                  >
                    {'Settings'}
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Hide Display Settings"
                  arrow={true}
                  placement="left"
                >
                  <Button
                    onClick={() => {
                      setShowDisplaySettings(false)
                    }}
                    variant="outlined"
                    size="small"
                    startIcon={<IconX size={14} />}
                    className="display-settings-button right flat"
                  >
                    {'Hide settings'}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Row>
        </Row>
      </Row>
      <Row
        sx={{
          ...displaySettingsSx,
          ...(showDisplaySettings ? { display: 'flex' } : { display: 'none' }),
        }}
      >
        <Row className="chip-row display-settings-chip-row">
          <ColumnSelectorChip />
          {pinnedColumns?.left?.length > 0 && (
            <Chip
              size="small"
              variant="outlined"
              className="pinned"
              label={`${pinnedColumns.left.length} pinned to left`}
              icon={
                <IconPinFilled
                  size={14}
                  className="pinned-left"
                />
              }
              onDelete={() => handleTogglePin('left')}
              deleteIcon={<IconX />}
            />
          )}
          {pinnedColumns?.right?.length > 0 && (
            <Chip
              size="small"
              variant="outlined"
              className="pinned"
              label={`${pinnedColumns.right.length} pinned to right`}
              icon={<IconPinFilled size={14} />}
              onDelete={() => handleTogglePin('right')}
              deleteIcon={<IconX />}
            />
          )}
          {(apiRef?.current.getSortModel() || []).map((sort) => (
            <Chip
              key={sort.field}
              size="small"
              variant="outlined"
              className="sorts"
              onDelete={() => handleToggleSort(sort.field, true)}
              deleteIcon={
                <IconX
                  size={12}
                  stroke={2.5}
                />
              }
              onClick={() => handleToggleSort(sort.field)}
              label={
                apiRef?.current
                  .getAllColumns()
                  .find((c) => c.field === sort.field)?.headerName || sort.field
              }
              icon={
                sort.sort === 'asc' ? (
                  <IconArrowBigUp
                    size={6}
                    stroke={2.5}
                  />
                ) : (
                  <IconArrowBigDown
                    size={6}
                    stroke={2.5}
                  />
                )
              }
            />
          ))}

          <ViewFilters />
        </Row>
      </Row>
    </Box>
  )
}

export default ViewManager
