import { useCallback, useContext, useMemo } from 'react'

import { Avatar } from '@mui/material'
import { IconBuildingSkyscraper } from '@tabler/icons-react'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAvatarCache } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { getCompanyLogoUrl } from 'src/lib/formatters'
import { ungatedWorkspacesForLogoDev } from 'src/lib/gates'

import { ENSURE_ORGANIZATION_LOGO_ASYNC } from '../Organizations/mutations'
import { GET_GLOBAL_ORGANIZATION_PHOTO_SQUARE } from '../Organizations/queries'

type DomainAvatarProps = {
  email?: string
  size?: number
  border?: number
  domain?: string
  photoUrl?: string
  borderRadius?: number
  companyColor?: string
  onClick?: () => void
  sx?: any
}

const getCacheKey = (props: DomainAvatarProps): string => {
  const { email, size, border, domain, photoUrl, borderRadius, companyColor } =
    props
  return `domain-${email}-${size}-${border}-${domain}-${photoUrl}-${borderRadius}-${companyColor}`
}

const BaseDomainAvatar = ({
  email,
  size,
  border,
  domain,
  photoUrl,
  borderRadius = 1000,
  companyColor,
  onClick,
  sx = {},
}: DomainAvatarProps) => {
  const { selectedWorkspace } = useContext(DayContext)

  const { data } = useQuery(GET_GLOBAL_ORGANIZATION_PHOTO_SQUARE, {
    variables: { domain },
  })

  const [ensureOrganizationLogoAsync] = useMutation(
    ENSURE_ORGANIZATION_LOGO_ASYNC
  )

  const handleLogoRequested = useCallback(() => {
    ensureOrganizationLogoAsync({ variables: { domain } })
  }, [domain, ensureOrganizationLogoAsync])

  const chip = data?.globalOrganization

  if (!domain && email) domain = extractEmailDomain(email)
  if (!domain) domain = ''
  domain = domain.toLowerCase().trim()

  const finalPhotoUrl = useMemo(() => {
    return photoUrl || chip?.photos?.square
  }, [photoUrl, chip])

  const finalCompanyColor = useMemo(() => {
    return companyColor || chip?.colors?.colorVibrant
  }, [companyColor, chip])

  const fontSize: string = size ? `${Math.floor(0.4 * size)}px` : '48px'
  const letter = (
    <IconBuildingSkyscraper size={size ? Math.floor(0.5 * size) : 20} />
  ) //domain.slice(0, 1)
  if (!border) border = 0
  const overridePhotoUrl = domain
    ? getCompanyLogoUrl({ domain, photoUrl: finalPhotoUrl })
    : finalPhotoUrl

  return (
    <Avatar
      className="domainAvatar"
      onClick={() => {
        handleLogoRequested()
        if (onClick) onClick()
      }}
      onError={() => {
        if (ungatedWorkspacesForLogoDev(selectedWorkspace)) {
          handleLogoRequested()
        }
      }}
      src={overridePhotoUrl}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        background: (theme) => finalCompanyColor || theme.palette.divider,
        border: (theme) =>
          `${border}px solid ${theme.palette.divider} !important`,
        borderRadius: `${borderRadius}px`,
        color: (theme) => theme.palette.primary.contrastText,
        fontSize,
        fontWeight: 600,
        textDecoration: 'none',
        '&:not(a)': {
          textDecoration: 'none',
        },
        '& img.MuiAvatar-img': {
          objectFit: 'contain',
        },
        ...sx,
      }}
    >
      {letter}
    </Avatar>
  )
}

const CachedDomainAvatar = (props: DomainAvatarProps) => {
  const { getCachedAvatar, setCachedAvatar } = useAvatarCache()
  const cacheKey = getCacheKey(props)

  const cachedAvatar = getCachedAvatar(cacheKey)
  if (cachedAvatar) {
    return cachedAvatar
  }

  const newAvatar = <BaseDomainAvatar {...props} />
  setCachedAvatar(cacheKey, newAvatar)
  return newAvatar
}

export default CachedDomainAvatar
