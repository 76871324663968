export const GET_PARALLEL_TASK = gql`
  query GetParallelTask {
    adminGetParallelOrgEnrichmentTask
  }
`

export const GET_GLOBAL_ORGANIZATION = gql`
  query GetGlobalOrganization($domain: String!) {
    globalOrganization(domain: $domain) {
      id
      name
      employeeCount
      photos {
        id
        square
        other
      }
      about {
        id
        description
        aiDescription
        employeesFrom
        employeesTo
        industry
        revenue
        funding
        news
        selling
        isHiring
        traffic
        founded
        icp
        phoneNumbers
      }
      purpose {
        missionAndVision
        values
        differentiators
      }
      colors {
        id
        colorDarkMuted
        colorLightMuted
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorVibrant
      }
      links {
        id
        linkedIn
        x
        facebook
        instagram
        youtube
        websiteResolvedUrl
        angellist
        crunchbase
      }
    }
  }
`

export const GET_GLOBAL_ORGANIZATION_PHOTO_SQUARE = gql`
  query GetGlobalOrganizationPhotoSquare($domain: String!) {
    globalOrganization(domain: $domain) {
      photos {
        id
        square
      }
      colors {
        id
        colorVibrant
      }
    }
  }
`

export const GET_GLOBAL_ORGANIZATION_ROWS = gql`
  query GetGlobalOrganizationRows($domain: String!) {
    globalOrganizationRows(domain: $domain)
  }
`

export const GET_GLOBAL_ORGANIZATION_PHOTOS = gql`
  query GetGlobalOrganizationPhotos {
    adminGlobalOrganizationPhotos
  }
`

export const GET_ORG_AGENT_DATA = gql`
  query GetOrgAgentData($workspaceId: String!, $domain: String!) {
    workspaceOrgAgentData(workspaceId: $workspaceId, domain: $domain) {
      data
      hydratedData
    }
  }
`

export const GET_DOMAINS_FOR_WORKSPACE = gql`
  query GetDomainsForWorkspace($workspaceId: String!) {
    workspaceOrganizationsAdmin(workspaceId: $workspaceId) {
      workspace {
        id
        name
      }
      domains {
        domain
        lastOverview
        lastActivity
        relationshipTypes
        deservesOverview
        needsOverviewUpdate
        needsOverviewUpdateReason
        dueForRefresh
        hasOpp
        lastSlackActivity
        mostRecentMeetingBookedAt
      }
      sharers {
        email
        sharesAllExternalMeetings
        sharesAllEmails
        workAccount {
          email
          uuid
          id
          createdAt
        }
      }
    }
  }
`

export const GET_ORGANIZATION_FOR_ADMIN = gql`
  query GetOrganizationForAdmin($domain: String!, $workspaceId: String!) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      updatedAt
      photos {
        id
        square
      }
      roles {
        id
        email
        name
        reasoning
        role
      }
      employeeCount
      annualRevenue
      funding
      colors {
        id
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorLightMuted
        colorDarkMuted
      }
      links {
        id
        facebook
        linkedIn
        x
        instagram
        youtube
        website
      }
      about {
        id
        aiDescription
        description
        industry
        selling
        employeesTo
        employeesFrom
      }
      relationship {
        id
        types {
          id
          label
          key
          value
        }
        upcomingEvents
        quotes {
          id
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        proofOfPaymentReasoning
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
    }
  }
`

export const GET_ORGANIZATION_FOR_OPP_SIDEBAR = gql`
  query SidebarGetOrganizationForOppSidebar(
    $workspaceId: String!
    $domain: String!
  ) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      colors {
        id
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorDarkMuted
        colorLightMuted
      }
      photos {
        id
        square
      }
    }
  }
`
