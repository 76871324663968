import { useCallback, useMemo, useRef, useState } from 'react'

import { Box, CircularProgress, Typography } from '@mui/material'
import type { GridCellEditStartParams } from '@mui/x-data-grid-premium'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license'
import {
  IconArrowBigDownFilled,
  IconArrowBigUpFilled,
  IconChartFunnelFilled,
  IconCircleFilled,
  IconRefresh,
} from '@tabler/icons-react'

import { useAuth } from 'src/auth'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import ObjectsColumnMenu from 'src/components/Objects/ObjectsTable/ObjectsColumnMenu'
import Row from 'src/components/Row/Row'
import DatagridToolbarViews from 'src/components/Views/DatagridToolbarViews'
import { ungatedForDayObjectsPart2 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { ObjectTypeMetadata } from 'src/lib/objects'

import { DayDataGridToolbar } from '../dataTables'

LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

const slugifyFilename = (str: string) => {
  if (!str) return ''
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

interface DayTableProps {
  id: string
  workspaceId: string
  title?: string
  subtitle?: string
  data: {
    columns: any[]
    rows: any[]
    waitingToQuery?: boolean
  }
  initialState?: any
  tabSets?: any
  onRowClick?: (params: any) => void
  rowObjectName?: string
  navItems?: any[]
  rowObjectNameSingular?: string
  showStats?: boolean
  headerLevel?: number
  csvFilename?: string | null
  showFilters?: boolean
  actionButtons?: any[]
  objectType?: keyof typeof ObjectTypeMetadata | null
  path?: string
  onNavEvent?: (value: any) => void
}

const DayTable = ({
  id,
  workspaceId = null,
  title,
  subtitle = null,
  data,
  initialState = {},
  tabSets = null,
  onRowClick,
  rowObjectName = 'rows',
  navItems = [],
  rowObjectNameSingular = 'row',
  showStats = true,
  headerLevel = 2,
  csvFilename = null,
  showFilters = true,
  actionButtons = [],
  objectType = null, // The type of object being displayed (e.g. NativeObjectTypes.Contact)
  onNavEvent = null,
}: DayTableProps) => {
  const { currentUser } = useAuth()
  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLDivElement | null>(
    null
  )
  const [viewsInitialized, setViewsInitialized] = useState(
    !(ungatedForDayObjectsPart2(currentUser) && objectType)
  )

  const isUngatedForViews = useMemo(() => {
    return ungatedForDayObjectsPart2(currentUser) && objectType
  }, [currentUser, objectType])

  const apiRef = useGridApiRef()

  const fullyLoaded = useMemo(() => {
    return !isUngatedForViews || (data.columns.length > 0 && viewsInitialized)
  }, [isUngatedForViews, data.columns, viewsInitialized])

  const objectName = useMemo(() => {
    if (objectType) {
      return ObjectTypeMetadata[objectType].pluralLabel
    }
    return rowObjectName
  }, [objectType, rowObjectName])

  const objectNameSingular = useMemo(() => {
    if (objectType) {
      return ObjectTypeMetadata[objectType].label
    }
    return rowObjectNameSingular
  }, [objectType, rowObjectNameSingular])

  //log({ passedRef: apiRef, init: initialized.current, ref: apiRef.current })

  const height = '100%'
  const width = '100%'

  const memoizedLoading = useMemo(() => data.waitingToQuery, [data])

  const icon = useMemo(() => {
    let iconToUse = IconCircleFilled
    if (objectType) {
      iconToUse = ObjectTypeMetadata[objectType].icon as any
    } else if (rowObjectNameSingular) {
      const objectTypeKey = `native_${rowObjectNameSingular}    `
      if (Object.keys(ObjectTypeMetadata).includes(objectTypeKey)) {
        iconToUse = ObjectTypeMetadata[objectTypeKey].icon as any
      }
    }
    return React.createElement(iconToUse)
  }, [objectType, rowObjectNameSingular])

  const dayTableStyle = useMemo(() => {
    return {
      height,
      width,
      '& .MuiDataGrid-toolbarContainer': {
        //border: 'none',
        overflow: 'visible',
      },
      '.MuiDataGrid-cell': {
        //border: 'none',
        cursor: onRowClick ? 'pointer' : 'default',
      },
      '&.MuiDataGrid-root': {
        border: 'none',
        borderWidth: '0px',
        '--DataGrid-containerBackground': (theme) =>
          theme.palette.background.paper,
        overflow: 'visible',
      },
      '.MuiDataGrid-main': {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        backgroundColor: (theme) => theme.palette.background.paper,
        '& .MuiDataGrid-virtualScroller': {
          //width: width - 2,
          '& .MuiDataGrid-virtualScrollerContent': {},
        },
        '& .MuiDataGrid-topContainer': {
          height: '40px',
        },
        '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
          borderBottom: 'none',
        },
      },
      '& .MuiDataGrid-columnHeader': {
        '& .editable-icon': {
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out',
          flexShrink: 0,
          cursor: 'pointer',
        },

        '&:hover .editable-icon': {
          opacity: 0.5,
        },

        '& .MuiDataGrid-menuIcon': {
          width: '0px !important',
          p: 0,
          m: 0,
          overflow: 'hidden !important',
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        cursor: 'pointer',
      },
      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        width: '100%',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        fontSize: '12px',
        letterSpacing: '-0.4px',
      },
      '& .MuiDataGrid-row:hover': {
        background: 'transparent',
      },
      '& .sort-icon': {},
      '& .MuiDataGrid-cell': {
        fontSize: '11px',
        letterSpacing: '-0.22px',
        opacity: 0.8,
        color: (theme) => theme.palette.text.primary,
        '& .MuiChip-sizeSmall': {},
        '& .MuiDataGrid-groupingCriteriaCell': {
          fontSize: '12px',
          letterSpacing: '-0.17px',
          opacity: 0.8,
          fontWeight: 600,
          color: (theme) => theme.palette.text.primary,
          '& .MuiDataGrid-groupingCriteriaCellToggle': {
            mr: 0,
            ml: '-8px',
          },
        },

        '&.MuiDataGrid-cell--editing': {
          boxShadow: 'none',
          outline: 'none',

          '&:focus-within': {
            outline: 'none',
          },
        },

        '& .MuiDataGrid-editInputCell': {
          '&.MuiInputBase-root': {
            '& .MuiInputBase-input': {
              padding: '0px 8px',
              border: 'none',
              fontSize: '11px',
              ml: '1px',
            },
          },
        },
      },
      '& .MuiDataGrid-aggregationColumnHeaderLabel': {
        fontWeight: 500,
        fontSize: '9px',
        letterSpacing: '-0.4px',
        opacity: 0.5,
      },
      '& .MuiDataGrid-menu': {},
      '& .MuiDataGrid-panelWrapper': {
        '& .MuiDataGrid-panelHeader': {},
        '& .MuiDataGrid-panelContent': {
          '& .MuiDataGrid-columnsPanel': {
            '& .MuiDataGrid-columnsPanelRow': {
              '& .MuiFormControlLabel-root': {
                '& .MuiSwitch-root': {
                  '& .MuiSwitch-switchBase': {},
                  '& .MuiSwitch-track': {},
                },
                '& .MuiTypography-root': {},
              },
            },
          },
          '& .MuiDataGrid-panelFooter': {},
        },
      },
    }
  }, [onRowClick])

  const rowEditUnderway = useRef(0)
  const clickTimeout = useRef(null)

  const handleCellClick = useCallback(
    (params) => {
      //logger.dev({ params, editUnderway: rowEditUnderway.current })
      if (rowEditUnderway.current) {
        return
      }

      if (!params.isEditable) {
        onRowClick?.(params)
        return
      }

      // If there's an existing timeout, this is a second click - don't do anything
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        return
      }

      // First click - set timeout
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null
        onRowClick?.(params) // Only fires if we got a single click
      }, 200)
    },
    [onRowClick]
  )

  const handleCellEditStart = useCallback(
    (_params: GridCellEditStartParams) => {
      rowEditUnderway.current = 1
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
      }
    },
    []
  )

  const handleCellEditStop = (cellEditStopParams) => {
    logger.dev('Stopping edit', { cellEditStopParams })
    setTimeout(() => {
      rowEditUnderway.current = 0
    }, 400)
  }

  const handleProcessRowUpdate = (_, updatedRow) => {
    logger.dev('Processing row update', { updatedRow, _ })
    return updatedRow
  }

  const [filterColumnRequested, setFilterColumnRequested] = useState<{
    field: string
    anchorEl: HTMLElement
  } | null>(null)

  const handleFilterColumnRequested = useCallback(
    (column: string, anchorEl: HTMLElement) => {
      logger.dev('Filter column requested:', column)
      setFilterColumnRequested({ field: column, anchorEl })
    },
    []
  )

  const resetFilterColumnRequested = useCallback(() => {
    setFilterColumnRequested(null)
  }, [])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          opacity: viewsInitialized ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          rows={data.rows}
          columns={data.columns}
          hideFooterSelectedRowCount={true}
          loading={memoizedLoading}
          initialState={initialState}
          disableRowSelectionOnClick={true}
          editMode="cell"
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            logger.warn(
              `Error updating object property: ${error.message}`,
              error.stack
            )
          }}
          onCellEditStart={handleCellEditStart}
          onCellEditStop={handleCellEditStop}
          slots={{
            toolbar: isUngatedForViews
              ? DatagridToolbarViews
              : DayDataGridToolbar,
            columnMenu: isUngatedForViews
              ? (params) => (
                  <ObjectsColumnMenu
                    {...params}
                    handleFilterColumnRequested={handleFilterColumnRequested}
                    objectType={objectType}
                  />
                )
              : null,
            columnSortedAscendingIcon: () => (
              <IconArrowBigUpFilled
                className="sort-icon"
                size={10}
              />
            ),
            columnSortedDescendingIcon: () => (
              <IconArrowBigDownFilled
                className="sort-icon"
                size={10}
              />
            ),
            columnFilteredIcon: () => <IconChartFunnelFilled size={12} />,
            columnMenuIcon: () => <></>,
            noResultsOverlay: () => (
              <Row
                sx={{ justifyContent: 'center', height: '100%', width: '100%' }}
              >
                <Typography variant="h4">{`No ${rowObjectName} match these filters`}</Typography>
              </Row>
            ),
            noRowsOverlay: () => (
              <Row
                sx={{ justifyContent: 'center', height: '100%', width: '100%' }}
              >
                <Typography variant="h4">{`No ${rowObjectName} to display`}</Typography>
              </Row>
            ),
            footerRowCount: (params) => {
              return (
                <>
                  {memoizedLoading ? (
                    <MetadataChip
                      state={{
                        label: 'Loading',
                        color: 'primary',
                        value: 'Loading',
                      }}
                      icon={<IconRefresh />}
                    />
                  ) : (
                    <Row sx={{ justifyContent: 'flex-end', width: '100%' }}>
                      <MetadataChip
                        state={{
                          label: `${params.visibleRowCount}
                    ${
                      params.visibleRowCount === 1
                        ? objectNameSingular || objectName
                        : objectName
                    }`,
                          color: 'primary',
                          value: `${params.visibleRowCount}`,
                        }}
                        icon={icon}
                      />
                      {params.visibleRowCount !== params.rowCount && (
                        <Typography sx={{ fontSize: '11px' }}>
                          {`${Math.round(
                            (params.visibleRowCount / params.rowCount) * 100
                          )}
                        %`}
                        </Typography>
                      )}
                    </Row>
                  )}
                </>
              )
            },
          }}
          //onRowClick={memoizedOnRowClick}
          onCellClick={handleCellClick}
          sortingOrder={['desc', 'asc']}
          slotProps={{
            toolbar: {
              id,
              setViewsInitialized,
              showColumnFilter: true,
              showFilter: showFilters,
              showQuickFilter: showFilters,
              showExport: showFilters,
              showDensitySelector: false,
              title,
              subtitle,
              navItems,
              onNavEvent,
              headerLevel,
              objectType,
              workspaceId,
              tabSets,
              initialState,
              rowObjectName: objectName,
              showStats,
              setPanelAnchorEl,
              showFilters,
              actionButtons,
              filterColumnRequested,
              resetFilterColumnRequested,
              csvOptions: {
                fileName: slugifyFilename(
                  csvFilename || `Day.ai ${rowObjectName || 'Export'}`
                ),
              },
            },
            panel: {
              anchorEl: panelAnchorEl,
            },
          }}
          columnHeaderHeight={40}
          rowHeight={40}
          sx={dayTableStyle}
          onFilterModelChange={(model) =>
            logger.dev('filterModelChange', model)
          }
        />
      </Box>
      {!fullyLoaded && (
        <Row
          sx={{
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
        >
          <Row>
            <CircularProgress />
          </Row>
        </Row>
      )}
    </>
  )
}

export default DayTable
