export const UPDATE_OBJECT_PROPERTY = gql`
  mutation UpdateObjectProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      success
    }
  }
`

export const POPULATE_OBJECT_PROPERTY_FROM_WEB = gql`
  mutation PopulateObjectPropertyFromWeb($input: PopulateObjectPropertyInput!) {
    populateObjectPropertyFromWeb(input: $input)
  }
`

export const POPULATE_OBJECT_PROPERTY_FROM_CONTEXT = gql`
  mutation PopulateObjectPropertyFromContext(
    $input: PopulateObjectPropertyInput!
  ) {
    populateObjectPropertyFromContext(input: $input)
  }
`

export const BACKFILL_PROPERTY_DEFINITION = gql`
  mutation BackfillObjectPropertyDefinition(
    $workspaceId: String!
    $objectType: String!
    $propertyDefinitionId: String!
    $testObjectIds: [String!]
  ) {
    backfillObjectProperty(
      workspaceId: $workspaceId
      objectType: $objectType
      propertyDefinitionId: $propertyDefinitionId
      testObjectIds: $testObjectIds
    )
  }
`

export const GET_OBJECT_PROPERTIES_REQUESTED = gql`
  mutation GetObjectPropertiesRequested(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
    $contextString: String!
    $targetProperties: [String!]
  ) {
    objectPropertiesRequested(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
      contextString: $contextString
      targetProperties: $targetProperties
    ) {
      updateableProperties {
        propertyId
        reasoning
      }
      additionalInformationRequested {
        propertyId
        question
      }
      additionalPropertiesRequested {
        name
        description
        type
      }
      previousContext
    }
  }
`

export const GET_REQUESTED_PROPERTY_UPDATES = gql`
  mutation GetRequestedPropertyUpdates(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
    $updateableProperties: PropertyUpdatesInput!
    $previousContext: String
    $currentContext: String
  ) {
    getRequestedPropertyUpdates(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
      updateableProperties: $updateableProperties
      previousContext: $previousContext
      currentContext: $currentContext
    ) {
      propertyUpdates {
        workspaceId
        objectType
        objectId
        propertyId
        value
        propertyType
        reasoning
        sources
        aiFeature
        aiFeatureVersion
      }
      userLoop {
        userQuestions
        completed
      }
      previousContext
    }
  }
`

export const TELL_DAY_AI = gql`
  mutation TellDayAi(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
    $contextString: String!
    $targetProperties: [String!]
    $autoApply: Boolean!
    $previousContext: String
  ) {
    tellDayAi(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
      contextString: $contextString
      targetProperties: $targetProperties
      autoApply: $autoApply
      previousContext: $previousContext
    ) {
      propertyUpdates {
        workspaceId
        objectType
        objectId
        propertyType
        propertyId
        value
        reasoning
        sources
        aiFeature
        aiFeatureVersion
      }
      userLoop {
        userQuestions
        completed
      }
      previousContext
    }
  }
`
