import {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  memo,
} from 'react'

import { Avatar } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { CRMObject, Page } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { GET_CHIP_METADATA } from 'src/components/Objects/queries'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { getTemplateIcon } from 'src/components/Templates/TemplateIcon/utils'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { getBestLabel } from 'src/lib/objectLabels'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { updateObjectInSearchIndex } from 'src/lib/searchService'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import DomainAvatar from '../../DomainAvatar/DomainAvatar'
import BaseChip from '../BaseChip'

const avatarSize = 16

interface ObjectChipProps {
  workspaceId: string
  crmObject: Partial<CRMObject>
  fullWidth?: boolean
  showSidebar?: boolean
  isSuggested?: boolean
  onAdd?: (object: Partial<CRMObject>) => void
  onRemove?: (object: Partial<CRMObject>) => void
}

const ObjectChipComponent = ({
  workspaceId,
  crmObject,
  fullWidth = false,
  showSidebar = false,
  isSuggested = false,
  onAdd = null,
  onRemove,
}: ObjectChipProps) => {
  const { setSidebarObject } = useContext(DayContext)
  const [localMetadata, setLocalMetadata] = useState<{
    label: string | null
    avatarUrl: string | null
  }>({ label: null, avatarUrl: null })

  const notFoundInIndexedDB = useRef(false)

  const {
    objectType,
    objectId,
    properties: objectProperties,
  } = useMemo(() => crmObject || {}, [crmObject])

  const { data } = useQuery(GET_CHIP_METADATA, {
    variables: {
      objectId,
      objectType,
      workspaceId,
    },
    skip:
      !objectId ||
      !objectType ||
      !workspaceId ||
      [NativeObjectTypes.MeetingRecording].includes(objectType as any) ||
      !notFoundInIndexedDB.current,
  })

  const chipMetadata = data?.chipMetadata

  // Update with server data when it arrives
  useEffect(() => {
    if (chipMetadata) {
      setLocalMetadata({
        label: chipMetadata.label,
        avatarUrl: chipMetadata.avatarUrl,
      })

      // Update the search index with the server data
      if (objectType && objectId && workspaceId) {
        // Create properties object without using spread
        const properties: Record<string, any> = { workspaceId }

        // Copy objectProperties if it exists
        if (objectProperties) {
          Object.keys(objectProperties).forEach((key) => {
            properties[key] = objectProperties[key]
          })
        }

        // Add specific properties from chipMetadata
        if (chipMetadata.domain) {
          properties.domain = chipMetadata.domain
        }
        if (chipMetadata.icon) {
          properties.icon = chipMetadata.icon
        }

        updateObjectInSearchIndex(objectType as NativeObjectType, objectId, {
          label: chipMetadata.label,
          photoUrl: chipMetadata.avatarUrl,
          avatarUrl: chipMetadata.avatarUrl,
          description: chipMetadata.description,
          properties,
        }).catch((error) => {
          logger.error('Failed to update search index with server data', error)
        })
      }
    }
  }, [chipMetadata, objectType, objectId, workspaceId, objectProperties])

  const handleSetSidebarObject = useCallback(() => {
    if (showSidebar) {
      setSidebarObject({
        objectType,
        objectId,
        properties: objectProperties,
      })
    }
  }, [showSidebar, objectType, objectId, objectProperties, setSidebarObject])

  const bestLabel = useMemo(() => {
    const bestLabel = getBestLabel({
      passedProperties: objectProperties as Record<string, any>,
      localMetadata,
      serverMetadata: chipMetadata,
      objectType: objectType as NativeObjectType,
      objectId,
    })
    return bestLabel
  }, [objectProperties, localMetadata, chipMetadata, objectType, objectId])

  const avatar = useMemo(() => {
    if (objectType === NativeObjectTypes.Organization) {
      return (
        <DomainAvatar
          domain={objectId}
          size={avatarSize}
        />
      )
    } else if (objectType === NativeObjectTypes.Person) {
      return (
        <ContactAvatar
          email={objectId}
          size={avatarSize}
        />
      )
    } else if (objectType === NativeObjectTypes.SlackChannel) {
      return <SlackLogo size={avatarSize} />
    } else if (
      objectType === NativeObjectTypes.Page &&
      (objectProperties as Page)?.templateType
    ) {
      return getTemplateIcon({
        templateType: (objectProperties as Page)?.templateType,
        variant: 'chip',
        size: avatarSize,
      })
    } else if (
      objectType === NativeObjectTypes.Page &&
      (objectProperties as Page)?.sourceTemplate?.templateType
    ) {
      return getTemplateIcon({
        templateType: (objectProperties as Page)?.sourceTemplate?.templateType,
        variant: 'chip',
        size: avatarSize,
      })
    } else {
      const objectIcon =
        ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
      return (
        <Avatar
          src={null}
          sx={{
            background: (theme) => theme.palette.background.default,
          }}
        >
          {React.createElement(objectIcon, {
            size: '12px',
            stroke: 2.45,
            style: {
              marginTop: '-1px',
            },
          })}
        </Avatar>
      )
    }
  }, [objectType, objectId, objectProperties])

  return (
    <BaseChip
      label={bestLabel || objectId}
      avatar={avatar}
      icon={null}
      onRemove={
        isSuggested
          ? () => onAdd(crmObject)
          : onRemove
            ? () => onRemove(crmObject)
            : null
      }
      fullWidth={fullWidth}
      onClick={
        showSidebar
          ? (e) => {
              e.stopPropagation()
              handleSetSidebarObject()
            }
          : null
      }
      isSuggested={isSuggested}
    />
  )
}

const ObjectChip = memo(ObjectChipComponent, (prevProps, nextProps) => {
  return (
    prevProps.crmObject.objectId === nextProps.crmObject.objectId &&
    prevProps.crmObject.objectType === nextProps.crmObject.objectType &&
    prevProps.crmObject.workspaceId === nextProps.crmObject.workspaceId
  )
})

export default ObjectChip
