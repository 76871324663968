import { useRef } from 'react'

import {
  Box,
  Chip,
  darken,
  IconButton,
  lighten,
  Stack,
  Typography,
} from '@mui/material'
import {
  useGridApiContext,
  type GridPinnedColumnPosition,
} from '@mui/x-data-grid-premium'
import {
  IconArrowBigDownFilled,
  IconArrowBigUpFilled,
  IconChartFunnel,
  IconPinFilled,
  IconTriangleSquareCircleFilled,
  IconX,
} from '@tabler/icons-react'

import Row from 'src/components/Row/Row'
import { objectPropertyDefinitionMetadata } from 'src/components/Sidebar/SidebarObjectCreate/objectPropertyDefinitions'
import PropertyTypeIcon from 'src/components/Views/PropertyTypeIcon'
import { colorStyles, viewsColors } from 'src/components/Views/styles'
import { logger } from 'src/lib/logger'

import useObjects from '../useObjects'

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  maxHeight: '600px',
  width: '280px',
  border: (theme) => `1px solid ${theme.palette.divider}`,
  py: '8px',
  px: '12px',

  '& .column-menu-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    pb: '4px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: 600,
      letterSpacing: '-0.3px',
    },
  },

  '& .column-type-chip': {
    width: '100%',
    borderRadius: '4px',
    flexShrink: 0,
    borderColor: lighten(viewsColors.columns, 0.5),
    backgroundColor: lighten(viewsColors.columns, 0.8),
    color: darken(viewsColors.columns, 0.4),
    justifyContent: 'flex-start',
    pl: '8px',
    '& .MuiChip-icon': {
      ml: '0px',
      mr: '4px',
    },
    '& .MuiChip-label': {
      fontSize: '11px',
      fontWeight: 500,
    },
  },

  '& .column-type-container': {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    boxShadow: (theme) => theme.shadows[1],
    '& .column-type-header': {
      borderBottom: 'none',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      backgroundColor: lighten(viewsColors.columns, 0.8),
      px: '8px',
      py: '2px',
      gap: '4px',
      '& .MuiTypography-root': {
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '-0.22px',
        color: darken(viewsColors.columns, 0.4),
      },
    },
    '& .column-description-text': {
      fontSize: '11px',
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: '-0.22px',
      p: '8px',
      borderRadius: '4px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
  },

  '& .action-section-title': {
    fontSize: '11px',
    fontWeight: 600,
    color: 'text.secondary',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    mb: 0.5,
    display: 'none',
  },

  '& .action-chip-stack': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },

  '& .MuiChip-root': {
    height: '26px',
    minHeight: '26px',
    flex: 1,
    justifyContent: 'space-between',
    '&.currently-active': colorStyles,
    '&.not-active': {
      borderColor: (theme) => theme.palette.action.disabledBackground,
      color: (theme) => theme.palette.text.secondary,
      '& .MuiChip-icon': {
        color: (theme) => theme.palette.text.secondary,
      },
    },
    '& .MuiChip-label': {
      fontSize: '11px',
      fontWeight: 500,
      px: '8px',
    },
    '& .MuiChip-icon': {
      marginLeft: '6px',
      marginRight: '-4px',
      height: '12px',
      width: '12px',
      flexShrink: 0,
      color: 'inherit',
    },
    '& .MuiChip-deleteIcon': {
      marginRight: '4px',
      marginLeft: '-4px',
      height: '14px',
      width: '14px',
      opacity: 0,
    },
  },

  '& .pinned-left': {
    transform: 'rotateY(180deg)',
  },

  '& .column-actions': {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    mt: 3,
    mb: 2,
  },
}

const ObjectsColumnMenu = (params) => {
  const { propertyDefinitions } = useObjects()
  const apiRef = useGridApiContext()
  const anchorEl = useRef<HTMLElement | null>(null)

  const allColumns = apiRef.current.getAllColumns()

  const objectType = params?.objectType
  const columnHeaderName = params?.colDef?.headerName
  const columnField = allColumns.find(
    (c) => c.headerName === columnHeaderName && !c.field.startsWith('__')
  )?.field

  const propertyDefinitionId = columnField
  const propertyDefinition =
    propertyDefinitions[objectType]?.[propertyDefinitionId]

  if (!propertyDefinition) {
    logger.warn(
      'Property definition not found for column in ObjectsColumnMenu',
      {
        objectType,
        propertyDefinitionId,
        columnField,
      }
    )
    return null
  }

  const propertyTypeId = propertyDefinition.propertyTypeId

  const gridState = apiRef.current.exportState()
  const sortModel = gridState.sorting.sortModel
  const filterModel = gridState.filter.filterModel.items
  const groupModel = gridState.rowGrouping.model
  const pinnedColumns = gridState.pinnedColumns

  const columnSort = sortModel.find((s) => s.field === propertyDefinitionId)
  const isSortedAsc = columnSort?.sort === 'asc'
  const isSortedDesc = columnSort?.sort === 'desc'

  const columnFilters = filterModel.filter(
    (f) => f.field === propertyDefinitionId
  )

  const isGrouped = groupModel.includes(propertyDefinitionId)

  const isPinnedLeft = pinnedColumns?.left?.includes(propertyDefinitionId)
  const isPinnedRight = pinnedColumns?.right?.includes(propertyDefinitionId)

  const handleSort = (direction: 'asc' | 'desc') => {
    const currentSort = sortModel.find((s) => s.field === propertyDefinitionId)
    let newSortModel = sortModel.filter((s) => s.field !== propertyDefinitionId)

    if (!(currentSort?.sort === direction)) {
      newSortModel = [
        //...newSortModel,
        { field: propertyDefinitionId, sort: direction },
      ]
    }

    apiRef.current.setSortModel(newSortModel)
  }

  const handleFilterAdd = () => {
    params.handleFilterColumnRequested(propertyDefinitionId, anchorEl.current)
    params.hideMenu()
  }

  const handleFilterRemove = (filterToRemove) => {
    const newFilters = filterModel.filter(
      (f) =>
        !(
          f.field === filterToRemove.field &&
          f.operator === filterToRemove.operator &&
          String(f.value) === String(filterToRemove.value)
        )
    )
    apiRef.current.setFilterModel({ items: newFilters })
  }

  const handleGroupingToggle = () => {
    const newGroupingModel = isGrouped
      ? groupModel.filter((id) => id !== propertyDefinitionId)
      : [...groupModel, propertyDefinitionId]
    apiRef.current.setRowGroupingModel(newGroupingModel)
  }

  const handlePin = (direction: 'left' | 'right' | null) => {
    const currentlyPinned = isPinnedLeft || isPinnedRight
    const pinningToSameDirection =
      (direction === 'left' && isPinnedLeft) ||
      (direction === 'right' && isPinnedRight)

    if (currentlyPinned) {
      apiRef.current.unpinColumn(propertyDefinitionId)
    }

    if (direction && !pinningToSameDirection) {
      apiRef.current.pinColumn(
        propertyDefinitionId,
        direction as GridPinnedColumnPosition
      )
    }
    params.hideMenu()
  }

  return (
    <Box
      sx={containerSx}
      ref={anchorEl}
    >
      <Row className="column-menu-header">
        <Typography>{propertyDefinition.name}</Typography>
        <IconButton
          onClick={params.hideMenu}
          size="small"
        >
          <IconX size={16} />
        </IconButton>
      </Row>

      <Box className="column-type-container">
        <Row className="column-type-header">
          <PropertyTypeIcon
            propertyTypeId={propertyTypeId}
            objectType={objectType}
            size={12}
          />
          <Typography className="column-type-label">
            {params?.colDef?.editable ? ' Editable ' : ' Non-editable '}
            {objectPropertyDefinitionMetadata[propertyTypeId]?.name ??
              'Unknown Type'}
            {' Property'}
          </Typography>
        </Row>

        <Typography className="column-description-text">
          {propertyDefinition.description}
        </Typography>
      </Box>

      <Box className="column-actions">
        <Box>
          <Typography className="action-section-title">Sort</Typography>
          <Stack
            direction="row"
            spacing={0.5}
            className="action-chip-stack"
          >
            <Chip
              icon={<IconArrowBigUpFilled size={12} />}
              label="Ascending"
              size="small"
              variant="outlined"
              onClick={() => handleSort('asc')}
              className={`sorts ${isSortedAsc ? 'currently-active' : 'not-active'}`}
              deleteIcon={<IconX size={12} />}
              onDelete={() => {}}
              disabled={!params?.colDef?.sortable}
            />
            <Chip
              icon={<IconArrowBigDownFilled size={12} />}
              label="Descending"
              size="small"
              variant="outlined"
              onClick={() => handleSort('desc')}
              className={`sorts ${isSortedDesc ? 'currently-active' : 'not-active'}`}
              deleteIcon={<IconX size={12} />}
              onDelete={() => {}}
              disabled={!params?.colDef?.sortable}
            />
          </Stack>
        </Box>

        <Box>
          <Typography className="action-section-title">Filter</Typography>
          <Stack
            direction="column"
            spacing={0.5}
            className="action-chip-stack"
          >
            {columnFilters.map((filter) => (
              <Chip
                key={`filter-${filter.field}-${filter.operator}-${filter.value}`}
                label={`Filtered for: ${filter.operator} "${filter.value}"`}
                size="small"
                icon={<IconChartFunnel size={12} />}
                variant="outlined"
                className="filters currently-active"
                onDelete={() => handleFilterRemove(filter)}
                deleteIcon={<IconX size={12} />}
                onClick={() => {}}
                disabled={!params?.colDef?.filterable}
              />
            ))}
            <Chip
              icon={<IconChartFunnel size={12} />}
              label="Use to filter data"
              size="small"
              variant="outlined"
              onClick={handleFilterAdd}
              className="filters not-active"
              deleteIcon={<IconX size={12} />}
              onDelete={() => {}}
              disabled={!params?.colDef?.filterable}
            />
          </Stack>
        </Box>

        <Box>
          <Typography className="action-section-title">Group</Typography>
          <Stack
            direction="row"
            spacing={0.5}
            className="action-chip-stack"
          >
            <Chip
              icon={<IconTriangleSquareCircleFilled size={12} />}
              label={isGrouped ? 'Grouped' : 'Group by property'}
              size="small"
              variant="outlined"
              onClick={handleGroupingToggle}
              className={`groupings ${isGrouped ? 'currently-active' : 'not-active'}`}
              onDelete={() => {}}
              deleteIcon={<IconX size={12} />}
              disabled={!params?.colDef?.groupable}
            />
          </Stack>
        </Box>

        <Box>
          <Typography className="action-section-title">Pin</Typography>
          <Stack
            direction="row"
            spacing={0.5}
            className="action-chip-stack"
          >
            <Chip
              icon={
                <IconPinFilled
                  size={12}
                  className="pinned-left"
                />
              }
              label="Pin to left"
              size="small"
              variant="outlined"
              onClick={() => handlePin('left')}
              className={`pinned ${isPinnedLeft ? 'currently-active' : 'not-active'}`}
              deleteIcon={<IconX size={12} />}
              onDelete={() => {}}
              disabled={!params?.colDef?.pinnable}
            />
            <Chip
              icon={<IconPinFilled size={12} />}
              label="Pin to right"
              size="small"
              variant="outlined"
              onClick={() => handlePin('right')}
              className={`pinned ${isPinnedRight ? 'currently-active' : 'not-active'}`}
              deleteIcon={<IconX size={12} />}
              onDelete={() => {}}
              disabled={!params?.colDef?.pinnable}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default ObjectsColumnMenu
