import { useContext, useState, useCallback } from 'react'

import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  IconButton,
  Tooltip,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { IconSettings, IconUserPlus } from '@tabler/icons-react'

import { navigate, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import InviteWorkspaceMemberDialog from 'src/components/InviteWorkspaceMemberDialog/InviteWorkspaceMemberDialog'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

// Memoized styles
const formControlStyles = {
  width: '100%',
  height: '48px',
  overflow: 'hidden',
  '&:hover': {
    '& .MuiOutlinedInput-root': {
      color: (theme) => theme.palette.primary.dark,
      borderRadius: 0,
    },
    '& .MuiSelect-icon': {
      color: (theme) => theme.palette.primary.dark,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) => `${theme.palette.primary.dark} !important`,
    },
    '& .MuiInputLabel-root': {
      color: (theme) => theme.palette.primary.dark,
    },
  },
}

const menuProps = {
  id: 'workspace-select-menu',
  sx: {
    '& .MuiMenu-paper': {
      width: '400px',
      minWidth: '400px',
    },
  },
}

const avatarStyles = {
  height: '24px',
  width: '24px',
  borderRadius: '100px',
  flexShrink: 0,
  m: '12px',
  ml: '4px',
}

const rowStyles = {
  justifyContent: 'space-between',
  width: '100%',
}

const workspaceNameStyles = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '140%',
  letterSpacing: '-0.2px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const defaultWorkspaceTextStyles = {
  fontSize: '10px',
  fontWeight: 300,
  letterSpacing: '-0.2px',
  color: (theme) => theme.palette.text.secondary,
}

const emptyBoxStyles = {
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  borderRadius: 0,
}

const inviteMenuItemStyles = {
  fontSize: '12px',
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
  px: '10px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '0px',
    pl: '0px',
    mr: 2,
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '-0.22px',
    },
  },
}

const workspaceBoxStyles = {
  fontSize: '12px',
  transition: 'all 0.22s ease',
}

const workspaceImageStyles = {
  width: '24px',
  height: '24px',
  objectFit: 'cover',
  borderRadius: '100px',
}

const marginRightStyles = {
  mr: '6px',
  '& .domainavatar, .MuiAvatar-root': {
    m: 0,
  },
}

function getWorkspaceLogo(workspace) {
  if (workspace.isDefault) return `${process.env.HOST}/logos/logo-blue.png`

  return `${process.env.HOST}/logos/logo-blue-inverse.png`

  /* later if ever:
  if (!workspace.name || workspace.name.length < 2) {
    return `${process.env.HOST}/logos/logo-blue-inverse.png`
  }

  const charSum = workspace.name.charCodeAt(0) + workspace.name.charCodeAt(1)
  const logoIndex = (charSum % 24) + 1

  return `${process.env.HOST}/logos/Day ${logoIndex}.png`
  */
}

export default function WorkspaceDropdown() {
  const { currentUser: user } = useAuth()
  const {
    selectedWorkspace,
    setSelectedWorkspace,
    workspaces,
    workAccountWorkspaceConnections,
  } = useContext(DayContext)

  const workspace = workspaces?.find(
    (workspace) => workspace.id === selectedWorkspace
  )
  const roles = workspace?.roles

  const [openInviteDialog, setOpenInviteDialog] = useState(false)

  const [open, setOpen] = useState(false)

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const currentUserRoleId = (workspace?.members || []).find(
    (member) => member.id === user.id
  )?.roleId

  const currentUserRole = (roles || []).find(
    (role) => role.id === currentUserRoleId
  )

  const canInviteMembers = (currentUserRole?.permissions || []).some(
    ({ type, permission }) => type === 'USERS' && permission === 'manage'
  )

  const handleChange = useCallback(
    (event) => {
      if (event.target.value === 'CREATE') {
        navigate(routes.workspaceCreate())
        onClose()
      } else {
        if (event.target.value) {
          setSelectedWorkspace(event.target.value)
          onClose()
          if (
            workAccountWorkspaceConnections?.some(
              ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
            )
          ) {
            navigate(routes.today())
            onClose()
          } else {
            navigate(routes.today())
            onClose()
          }
        }
      }
    },
    [onClose, setSelectedWorkspace, workAccountWorkspaceConnections]
  )

  const selectStyles = {
    borderRadius: 0,
    ml: '8px',
    color: (theme) => theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '12px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      p: 0,
    },
    '& .MuiSelect-icon': {
      transition: 'all 0.22s ease',
    },
    '& .MuiSelect-select': {
      borderRadius: '0px !important',
    },

    '& .MuiList-root.MuiMenu-list': {
      py: 0,
    },
    '& .MuiSelect-iconOutlined': {
      display: 'none',
    },
    '& .menu-item, .MuiButtonBase-root.MuiMenuItem-root.Mui-selected, .MuiButtonBase-root.MuiMenuItem-root':
      {
        p: 1,
        minHeight: '48px',
        height: '48px',
      },
  }

  const renderValue = useCallback(
    (value) => {
      const workspace = workspaces.find((w) => w.id === value)
      if (!workspace) return null

      return (
        <Row>
          <Box>
            {workspace?.domains?.length > 0 && workspace.domains[0].domain ? (
              <DomainAvatar
                domain={workspace.domains[0].domain}
                size={24}
                borderRadius={100}
                sx={avatarStyles}
              />
            ) : (
              <Box
                component="img"
                src={getWorkspaceLogo(workspace)}
                alt={workspace.name}
                sx={avatarStyles}
              />
            )}
          </Box>
        </Row>
      )
    },
    [workspaces]
  )

  const handleSettingsClick = useCallback(
    (e, workspace) => {
      e.stopPropagation()
      e.preventDefault()
      if (selectedWorkspace !== workspace.id) {
        setSelectedWorkspace(workspace.id)
        onClose()
      }
      navigate(routes.workspaceSettings({ id: workspace.id }))
      onClose()
    },
    [selectedWorkspace, setSelectedWorkspace, onClose]
  )

  if (!selectedWorkspace || !workspaces?.length) {
    return <Box sx={emptyBoxStyles} />
  }

  return (
    <FormControl
      variant="outlined"
      sx={formControlStyles}
    >
      <Select
        labelId="workspace-select-label"
        id="workspace-select"
        value={selectedWorkspace}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={onClose}
        MenuProps={menuProps}
        renderValue={renderValue}
        onChange={handleChange}
        fullWidth={true}
        sx={selectStyles}
      >
        {workspaces
          .filter(({ status }) => ['ACTIVE', 'INVITED'].includes(status))
          .map((workspace) => (
            <MenuItem
              key={workspace.id}
              value={workspace.id}
              selected={selectedWorkspace === workspace.id}
              className="menu-item"
              sx={{
                p: 1,
                minHeight: '48px',
                height: '48px',
              }}
            >
              <Row sx={rowStyles}>
                <Row gap={1}>
                  <Box sx={marginRightStyles}>
                    {workspace?.domains?.length > 0 &&
                    workspace.domains[0].domain ? (
                      <DomainAvatar
                        domain={workspace.domains[0].domain}
                        size={24}
                        borderRadius={100}
                      />
                    ) : (
                      <Box
                        component="img"
                        src={getWorkspaceLogo(workspace)}
                        alt={workspace.name}
                        sx={workspaceImageStyles}
                      />
                    )}
                  </Box>
                  <Box sx={workspaceBoxStyles}>
                    <Typography sx={workspaceNameStyles}>
                      {workspace.name || 'Unknown'}
                    </Typography>
                    {workspace.isDefault && (
                      <Typography sx={defaultWorkspaceTextStyles}>
                        Default workspace
                      </Typography>
                    )}
                  </Box>
                </Row>
                <Tooltip
                  title="Workspace Settings"
                  arrow={true}
                  placement="right"
                >
                  <IconButton
                    onClick={(e) => handleSettingsClick(e, workspace)}
                  >
                    <IconSettings size={16} />
                  </IconButton>
                </Tooltip>
              </Row>
            </MenuItem>
          ))}
        <ListItemButton
          sx={inviteMenuItemStyles}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpenInviteDialog(true)
          }}
        >
          <ListItemIcon>
            <IconUserPlus size={20} />
          </ListItemIcon>
          <ListItemText>Invite a team member</ListItemText>
        </ListItemButton>
      </Select>
      {canInviteMembers && (
        <InviteWorkspaceMemberDialog
          title="Invite a team member"
          workspaceId={workspace.id}
          allRoles={roles}
          open={openInviteDialog}
          onClose={() => {
            setOpenInviteDialog(false)
          }}
        />
      )}
    </FormControl>
  )
}
