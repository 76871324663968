import type { DayObject } from 'types/graphql'

import { dayjs } from '../dayjs'
import { logger } from '../logger'
import type { NativeObjectType } from '../objects'
import { StageTypes } from '../objects'
import { NativePipelineTypes } from '../objects'
import { NativeObjectTypes } from '../objects'

export const ensureArrayOfStringsFromJsonString = (value: any): string[] => {
  if (!value) return []
  try {
    const parsed = JSON.parse(value)
    if (Array.isArray(parsed)) {
      return parsed
        .map((item) => (item ? item.toString() : null))
        .filter(Boolean)
    }
    return [value.toString()]
  } catch (e) {
    logger.warn(`ensureArrayOfStringsFromJsonString: ${e} ${value}`)
    return []
  }
}

export const ensureJsonOrNullFromArray = (value: any): string | null => {
  try {
    if (Array.isArray(value) && value.length > 0) {
      return JSON.stringify(value)
    }
  } catch (e) {
    logger.warn(`ensureJsonOrNullFromArray: ${e} ${value}`)
  }

  return null
}

export const ensureArrayOfObjectsFromJsonString = (value: any): object[] => {
  try {
    const parsed = JSON.parse(value)
    if (Array.isArray(parsed)) {
      return parsed
    }
  } catch (e) {
    //logger.warn(`ensureArrayOfObjectsFromJsonString: ${e} ${value}`)
  }
  return []
}

export const ensureObjectFromJsonString = (value: any): object => {
  try {
    return JSON.parse(value)
  } catch (e) {
    logger.warn(`ensureObjectFromJsonString: ${e} ${value}`)
  }
  return {}
}

export const ensureDateFromUnknown = (value: any) => {
  if (typeof value === 'string') {
    try {
      return new Date(value)
    } catch (e) {
      logger.warn(`ensureDateFromUnknown: ${e} ${value}`)
    }
  } else if (typeof value === 'number') {
    return new Date(value)
  } else if (value instanceof Date) {
    return value
  }

  return null
}

export const ensureDateTime = (value: any) => {
  return value ? (typeof value !== 'string' ? value : new Date(value)) : null
}

export const ensureUrl = (value: any): string | undefined => {
  if (typeof value === 'string') {
    // Trim the value to remove any leading/trailing whitespace
    const trimmedValue = value.trim()

    // Check if the URL starts with a protocol, if not, prepend 'https://'
    const urlWithProtocol =
      trimmedValue.startsWith('http://') || trimmedValue.startsWith('https://')
        ? trimmedValue
        : `https://${trimmedValue}`

    try {
      const url = new URL(urlWithProtocol)
      return url.toString()
    } catch (e) {
      logger.warn(`Failed to parse URL: ${value}`, { error: e.message })
    }
  } else if (value !== undefined) {
    logger.warn(`Invalid URL value type: ${typeof value}`)
  }
  return undefined
}

export const ensureXUrl = (value: any): string => {
  if (typeof value === 'string') {
    if (value.startsWith('https://x.com/')) {
      return value
    } else if (value.startsWith('@')) {
      return `https://x.com/${value.slice(1)}`
    } else if (value.startsWith('https://twitter.com/')) {
      return value.replace('https://twitter.com/', 'https://x.com/')
    } else if (!value.startsWith('https://')) {
      return `https://x.com/${value}`
    } else {
      return value
    }
  }
}

const extractLinkedInId = (url) => {
  if (!url) {
    return null
  }

  // Handle simple username with trailing slash
  if (url.match(/^[^/]+\/$/) || url.match(/^[^/]+$/)) {
    const result = url.replace(/\/$/, '')
    return result
  }

  const regex = /(linkedin\.com\/in\/|\/in\/)([^/?]+)(?:-[a-zA-Z0-9]+)?\/?\s*$/
  const match = url.match(regex)
  if (match) {
    // Further process the matched ID to remove any trailing alphanumeric identifier
    const result = match[2].replace(/-[a-zA-Z0-9]+$/, '')
    return result
  }
  return null
}

export const ensureLinkedInProfileUrl = (value: string): string => {
  const linkedInId = extractLinkedInId(value)
  return linkedInId ? `https://www.linkedin.com/in/${linkedInId}` : null
}

export const ensureIntegerFromString = (value: string): number => {
  const parsed = parseInt(value, 10)
  return isNaN(parsed) ? null : parsed
}

export const ensureFloatFromString = (value: string): number => {
  const parsed = parseFloat(value)
  return isNaN(parsed) ? null : parsed
}

export const ensureArrayOfIntegersFromJsonString = (
  value: string
): number[] => {
  try {
    const parsed = JSON.parse(value)
    return parsed.every((item) => typeof item === 'number') ? parsed : null
  } catch (e) {
    logger.warn(`ensureArrayOfIntegersFromString: ${e} ${value}`)
    return null
  }
}

export const ensureValidObjectReference = (
  value: string,
  allowGeneric = true
): string | null => {
  try {
    if (!value) return null

    // Check if the value matches UUID-STRING-UUID pattern
    const parts = value.split(' : ')
    if (parts.length !== 3) {
      //logger.info(`ensureValidObjectReference: ${value}`)
      const isValid = [
        ...Object.values(StageTypes),
        ...Object.keys(NativePipelineTypes),
      ].includes(value)
        ? value
        : null
      //logger.info(`isValid: ${isValid}`)
      return isValid
    }

    // Validate first and last parts are UUIDs
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
    const start = parts[0].trim()
    const middle = parts[1].trim()
    const end = parts[2].trim()
    const startIsUuid = uuidRegex.test(start)
    const endIsUuid = uuidRegex.test(end)
    const endIsGenericPipelineOrStage = [
      ...Object.values(StageTypes),
      ...Object.keys(NativePipelineTypes),
      ...[
        ...Object.values(StageTypes),
        ...Object.keys(NativePipelineTypes),
      ].flatMap((type1) =>
        [...Object.values(StageTypes), ...Object.keys(NativePipelineTypes)].map(
          (type2) => `${type1}-${type2}`
        )
      ),
    ].includes(end)
    const middleIsNativeObjectType = Object.values(NativeObjectTypes).includes(
      middle as NativeObjectType
    )
    if (
      !startIsUuid ||
      !(endIsUuid || (allowGeneric && endIsGenericPipelineOrStage)) ||
      !middleIsNativeObjectType
    ) {
      //logger.warn(`Invalid object reference format: ${value}`)
      return null
    }

    return value
  } catch (e) {
    //logger.warn(`Invalid object reference format: ${value}`)
    return null
  }
}

export const objectIdsFromObjectsArrayAsString = (objects: DayObject[]) => {
  if (!objects || !Array.isArray(objects)) return null

  return objects.map((object) => object.objectId).join(' ')
}

export const renderCertifications = (certificationsString: string) => {
  try {
    const certifications = JSON.parse(certificationsString)
    if (Array.isArray(certifications)) {
      return certifications
        .map((cert) => {
          if (typeof cert === 'string') {
            return {
              name: cert,
              issuer: null,
              date: null,
              url: null,
            }
          } else if (typeof cert === 'object') {
            if (cert.deleted === 1) {
              return null
            }

            const dateString = cert.date_to || cert.date_from || null
            const date = dateString ? dayjs(dateString).toDate() : null
            logger.info(`date: ${date}`)
            return {
              name: cert.name,
              issuer: cert.authority,
              date,
              url: cert.url,
            }
          }
        })
        .filter(Boolean)
    } else {
      return []
    }
  } catch (e) {
    logger.warn(`Error rendering certifications: ${e}`)
    return []
  }
}
