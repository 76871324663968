import {
  IconCalculator,
  IconCalendar,
  IconCheckbox,
  IconCurrencyDollar,
  IconHash,
  IconLetterCase,
  IconLink,
  IconMail,
  IconPhone,
  IconTag,
  IconTags,
} from '@tabler/icons-react'

import { PropertyTypes } from 'src/lib/objects'

export const objectPropertyDefinitionMetadata = {
  [PropertyTypes.Picklist]: {
    name: 'Picklist',
    description: 'A picklist property',
    icon: IconTag,
    showUser: true,
  },
  [PropertyTypes.MultiPicklist]: {
    name: 'Multi-Select',
    description: 'A list of values, with ability for multiple to be selected',
    icon: IconTags,
    showUser: true,
  },
  [PropertyTypes.TextArea]: {
    name: 'Text',
    description: 'A text property',
    icon: IconLetterCase,
    showUser: true,
  },
  [PropertyTypes.Integer]: {
    name: 'Number (no decimals)',
    description: 'An integer number property',
    icon: IconHash,
    showUser: true,
  },
  [PropertyTypes.Float]: {
    name: 'Number (with decimals)',
    description: 'A floating point number property',
    icon: IconHash,
    showUser: true,
  },
  [PropertyTypes.DateTime]: {
    name: 'Date / Time',
    description: 'A date property',
    icon: IconCalendar,
    showUser: true,
  },
  [PropertyTypes.Boolean]: {
    name: 'True / False',
    description: 'A boolean property',
    icon: IconCheckbox,
    showUser: true,
  },
  [PropertyTypes.Email]: {
    name: 'Email Address',
    description: 'An email property',
    icon: IconMail,
    showUser: true,
  },
  [PropertyTypes.Phone]: {
    name: 'Phone Number',
    description: 'A phone property',
    icon: IconPhone,
    showUser: true,
  },
  [PropertyTypes.Url]: {
    name: 'URL',
    description: 'A URL property',
    icon: IconLink,
    showUser: true,
  },
  [PropertyTypes.Currency]: {
    name: 'Currency',
    description: 'A currency property',
    icon: IconCurrencyDollar,
    showUser: true,
  },
  [PropertyTypes.Combobox]: {
    name: 'Combobox',
    description: 'A combobox property',
    icon: IconTag,
    showUser: false,
  },
  [PropertyTypes.Calculated]: {
    name: 'Calculated',
    description: 'A calculated property',
    icon: IconCalculator,
    showUser: false,
  },
}
