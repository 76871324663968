// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import { useAuth } from './auth'
import StyleGuidesPage from './components/Actions/StyleGuides/StyleGuides'
import ModernLayout from './layouts/ModernLayout/ModernLayout'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import PublicSiteLayout from './layouts/PublicSiteLayout/PublicSiteLayout'
import TemplatesPage from './pages/TemplatesPage/TemplatesPage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route
        path="/"
        page={HomepageSpringLaunch2024Page}
        name="homepageSpringLaunch2024"
      />
      <Route
        path="/airplane-mode"
        page={AirplaneModePage}
        name="airplaneMode"
      />
      <Route
        path="/zero-playground"
        page={ZeroPlaygroundPage}
        name="zeroPlayground"
      />
      <Set wrap={PublicSiteLayout}>
        <Route
          path="/sequoia-backs-ai-native-crm"
          page={FundingAnnouncementPage}
          name="funding"
        />
        <Route
          path="/pricing"
          page={PricingPage}
          name="pricing"
        />
        <Route
          path="/terms"
          page={PublicSiteTermsPage}
          name="terms"
        />
        <Route
          path="/privacy"
          page={PublicSitePrivacyPage}
          name="privacy"
        />
        <Route
          path="/support"
          page={PublicSiteSupportPage}
          name="support"
        />
        <Route
          path="/zoom"
          page={PublicSiteZoomHelpPage}
          name="zoomHelp"
        />
        <Route
          path="/data"
          page={PublicSiteDataRetentionPage}
          name="dataRetention"
        />
      </Set>
      <PrivateSet unauthenticated="login">
        <Route
          path="/integrations/authorize"
          page={OauthOauthPage}
          name="oauthAuthorize"
        />
        <Route
          path="/integrations/redirect"
          page={OauthOauthDummyRedirectPage}
          name="oauthRedirect"
        />
        <Set wrap={OnboardingLayout}>
          <Route
            path="/finish-login"
            page={FinishLoginPage}
            name="finishLogin"
          />
          <Route
            path="/welcome"
            page={WelcomePage}
            name="welcome"
          />
          <Route
            path="/add-work-account"
            page={AddWorkAccountPage}
            name="addWorkAccount"
          />
        </Set>
        <Set wrap={ModernLayout}>
          <Route
            path="/search/index"
            page={IndexPage}
            name="index"
          />
          <Route
            path="/data/debug"
            page={DataPage}
            name="data"
          />
          <Route
            path="/slack"
            page={SlackPage}
            name="slack"
          />
          <Route
            path="/admin"
            page={AdminPage}
            name="admin"
          />
          <Route
            path="/admin/data/feedback"
            page={AdminDataFeedbackPage}
            name="adminDataFeedback"
          />
          <Route
            path="/admin/properties/collect"
            page={AdminPropertyCollectorPage}
            name="propertyCollector"
          />
          <Route
            path="/admin/invites/{workspaceId}"
            page={AdminInvitesPage}
            name="adminInvites"
          />
          <Route
            path="/admin/import/{workspaceId}"
            page={AdminImportPage}
            name="adminImport"
          />
          <Route
            path="/admin/{email}/edit"
            page={DayAdminUserPage}
            name="dayAdminUser"
          />
          <Route
            path="/admin/orgs/{workspaceId}"
            page={AdminOrganizationsPage}
            name="adminOrganizations"
          />
          <Route
            path="/admin/orgs/{workspaceId}/{domain}/agent"
            page={AdminAdminOrgAgentPage}
            name="adminOrgAgent"
          />
          <Route
            path="/admin/opportunities/{workspaceId}"
            page={AdminOpportunitiesPage}
            name="adminOpportunities"
          />
          <Route
            path="/admin/opportunity/{workspaceId}/{opportunityId}"
            page={AdminOpportunityPage}
            name="adminOpportunity"
          />
          <Route
            path="/admin/roles"
            page={AdminOppRolesPage}
            name="adminOppRoles"
          />
          <Route
            path="/admin/opps/approvals"
            page={AdminGenericOppApprovalsPage}
            name="adminGenericOppApprovals"
          />
          <Route
            path="/admin/organizations"
            page={AdminOrganizationsAllPage}
            name="adminOrganizationsAll"
          />
          <Route
            path="/admin/slack"
            page={AdminSlackAllPage}
            name="adminSlackAll"
          />
          <Route
            path="/integrations/admin"
            page={AdminOauthPage}
            name="adminOauth"
          />
          <Route
            path="/admin/organizations/{domain}/enrich"
            page={AdminOrganizationGlobalPage}
            name="adminOrganizationGlobal"
          />
          <Route
            path="/admin/actions/debug/{workspaceId}"
            page={AdminActionsDebugPage}
            name="adminActionsDebug"
          />
          <Route
            path="/admin/actions/{workspaceId}/{actionId}"
            page={AdminActionPage}
            name="adminAction"
          />
          <Route
            path="/admin/actions/{workspaceId}"
            page={AdminActionsPage}
            name="adminActions"
          />
          <Route
            path="/admin/pipelines/setup"
            page={AdminPipelineSetupPage}
            name="adminPipelineSetup"
          />
          <Route
            path="/admin/slack/{workspaceId}"
            page={AdminSlackPage}
            name="adminSlack"
          />
          <Route
            path="/admin/people"
            page={AdminPeoplePage}
            name="adminPeople"
          />
          <Route
            path="/admin/person/{email}/edit"
            page={AdminPersonPage}
            name="adminPerson"
          />
          <Route
            path="/admin/people/{workspaceId}"
            page={AdminPeopleWorkspacePage}
            name="adminPeopleWorkspace"
          />
          <Route
            path="/admin/workspace/{workspaceId}"
            page={AdminWorkspacePage}
            name="adminWorkspace"
          />
          <Route
            path="/admin/{domain}/go"
            page={AdminWorkspaceDomainRedirectPage}
            name="adminWorkspaceDomainRedirect"
          />
          <Route
            path="/admin/zapier/test"
            page={AdminAdminZapierTestUpsertPage}
            name="adminZapierTestUpsert"
          />

          <Route
            path="/{workspaceId}/import"
            page={ImportImportPage}
            name="import"
          />

          <Route
            path="/crm"
            page={ObjectsPage}
            name="crm"
          />

          <Route
            path="/{workspaceId}/import/{id}"
            page={ImportImportDetailPage}
            name="importDetail"
          />
          <Route
            path="/sync"
            page={SyncPage}
            name="sync"
          />
          <Route
            path="/test"
            page={TestPage}
            name="test"
          />
          <Route
            path="/add-zoom-account"
            page={AddZoomAccountPage}
            name="addZoomAccount"
          />
          <Route
            path="/add-linkedin-account"
            page={AddLinkedInAccountPage}
            name="addLinkedInAccount"
          />
          <Route
            path="/add-slack"
            page={AddSlackPage}
            name="addSlack"
          />
          <Route
            path="/today"
            page={TodayPage}
            name="today"
          />
          <Route
            path="/home"
            page={HomePage}
            name="home"
          />
          <Route
            path="/threads/{threadId}"
            page={ThreadsV2ThreadPage}
            name="thread"
          />
          <Route
            path="/library"
            page={LibraryPage}
            name="library"
          />
          <Route
            path="/workspace/{workspaceId:String}/library"
            page={WorkspaceLibraryPage}
            name="workspaceLibrary"
          />
          <Route
            path="/review/{id}"
            page={ReviewMeetingPage}
            name="reviewMeeting"
          />
          <Route
            path="/workspace/{workspaceId:String}/recordings/{recordingId:String}"
            page={WorkspaceReviewMeetingPage}
            name="workspaceMeetingRecording"
          />
          <Route
            path="/recording/{id}/audit"
            page={RecordingAuditPage}
            name="recordingAudit"
          />
          <Route
            path="/workspace/{workspaceId:String}/recordings/{recordingId:String}/audit"
            page={WorkspaceAuditPage}
            name="workspaceMeetingRecordingAudit"
          />
          <Route
            path="/recordings/{recordingId:String}"
            page={MeetingRecordingAutomaticRedirectPage}
            name="meetingRecordingAutomaticRedirect"
          />

          <Route
            path="/pages"
            page={PagesPagesPage}
            name="pages"
          />

          <Route
            path="/pages/folders"
            page={PagesDirectoryPage}
            name="pagesDirectory"
          />
          <Route
            path="/page/{id}/audit"
            page={PageAuditPage}
            name="pageAudit"
          />
          <Route
            path="/page/{id}"
            page={PagesPageDetailPage}
            name="pageDetail"
          />
          <Route
            path="/pipelines"
            page={PipelinesPage}
            name="pipelines"
          />

          <Route
            path="/instructions"
            page={UserContextPage}
            name="instructions"
          />
          <Route
            path="/opportunities"
            page={OpportunitiesPage}
            name="opportunities"
          />

          <Route
            path="/recordings"
            page={RecordingsPage}
            name="recordings"
          />
          <Route
            path="/{workspaceId}/{objectTypeSlug}/{objectId}/view"
            page={ObjectDetailPage}
            name="objectDetail"
          />
          <Route
            path="/workspaces/sharing"
            page={WorkspaceSharingPage}
            name="workspaceSharing"
          />
          <Route
            path="/settings/{id:String}"
            page={SettingsPage}
            name="settings"
          />
          <Route
            path="/people"
            page={PeoplePage}
            name="people"
          />
          <Route
            path="/organizations"
            page={OrganizationsPage}
            name="organizations"
          />
          <Route
            path="/actions"
            page={ActionsPage}
            name="actions"
          />
          <Route
            path="/pipeline"
            page={PipelinePage}
            name="pipeline"
          />
          <Route
            path="/pipeline/debug/{workspaceId}/{pipelineId}"
            page={PipelineDebugPage}
            name="pipelineDebug"
          />
          <Route
            path="/reports/pipeline"
            page={ReportsPipelinePage}
            name="reportsPipeline"
          />
          <Route
            path="/templates"
            page={TemplatesPage}
            name="templates"
          />
          <Route
            path="/reports/activity"
            page={ReportsActivityPage}
            name="reportsActivity"
          />
          <Route
            path="/workspace/create"
            page={WorkspaceAdminWorkspaceCreatePage}
            name="workspaceCreate"
          />
          <Route
            path="/workspace/{id:String}/settings"
            page={WorkspaceAdminWorkspaceSettingsPage}
            name="workspaceSettings"
          />

          <Route
            path="/marketing"
            page={MarketingMarketingPage}
            name="marketing"
          />

          <Route
            path="/inbox"
            page={CustomersCustomerInboxPage}
            name="customerInbox"
          />
          <Route
            path="/customers"
            page={CustomersCustomersCurrentPage}
            name="customersCurrent"
          />
          <Route
            path="/product"
            page={ProductProductPage}
            name="product"
          />
          <Route
            path="/content"
            page={MarketingMarketingContentPage}
            name="marketingContent"
          />
          <Route
            path="/campaigns"
            page={MarketingMarketingCampaignsPage}
            name="marketingCampaigns"
          />
          <Route
            path="/test-blocks"
            page={TestBlockRenderingPage}
            name="testBlockRendering"
          />
          <Route
            path="/revoke-alert"
            page={RevokeAlertPage}
            name="revokeAlert"
          />
          <Route
            path="/admin/people-debug"
            page={AdminPeopleDebugPage}
            name="adminPeopleDebug"
          />
          <Route
            path="/admin/orgs/global/photos"
            page={AdminAdminOrgPhotosPage}
            name="adminOrgPhotos"
          />
          <Route
            path="/admin/organizations-debug"
            page={AdminOrganizationsDebugPage}
            name="adminOrganizationsDebug"
          />
          <Route
            path="/admin/test"
            page={AdminTestPage}
            name="adminTest"
          />
          <Route
            path="/styleguides"
            page={StyleGuidesPage}
            name="styleGuides"
          />
        </Set>
      </PrivateSet>
      <Route
        path="/homepageWinter2024"
        page={PublicSiteHomepageWinter2024Page}
        name="homepageWinter2024"
      />
      <Route
        path="/login"
        page={LoginPage}
        name="login"
      />
      <Route
        path="/logout"
        page={LogOutPage}
        name="logout"
      />
      <Route
        path="/p/{shortLinkHash}"
        page={PagesPageShortLinkPage}
        name="pageShortLink"
      />
      <Route
        path="/page/{id}/share"
        page={PagePublicPage}
        name="pagePublic"
      />
      <Route
        path="/share/clip/{id}"
        page={ShareClipPage}
        name="shareClip"
      />
      <Route
        notfound
        page={NotFoundPage}
      />
    </Router>
  )
}

export default Routes
