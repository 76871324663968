import { useEffect, useMemo, useReducer, useState } from 'react'

import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  TextField,
  Tooltip,
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import {
  IconChartFunnel,
  IconSearch,
  IconTriangleSquareCircleFilled,
  IconX,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'
import type { NativeObjectType } from 'src/lib/objects'

import Row from '../Row/Row'

import PropertyTypeIcon from './PropertyTypeIcon'
import { OperatorMetadataByValue } from './styles'
import useViews from './useViews'

const menuWidth = '256px'

const ViewFilters = () => {
  const {
    objectType,
    filterColumnRequested,
    resetFilterColumnRequested: _,
  } = useViews()

  const initialState = {
    settingType: null,
    fieldToFilter: null,
    operator: null,
    anchorEl: null,
    value: null,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'setFieldToFilter':
        return { ...state, fieldToFilter: action.payload }
      case 'setOperator':
        return { ...state, operator: action.payload }
      case 'setSettingType':
        return { ...state, settingType: action.payload }
      case 'setAnchorEl':
        return { ...state, anchorEl: action.payload }
      case 'setFilterData':
        logger.dev('SET FILTER DATA', { payload: action.payload })
        return { ...state, value: action.payload.value }
      case 'setFilterColumnRequested':
        return {
          ...state,
          fieldToFilter: action.payload.column,
          anchorEl: action.payload.anchorEl,
          operator: null,
          settingType: 'filter',
        }
      case 'editFilter': {
        const { filter, anchorEl, operatorObject } = action.payload
        return {
          ...state,
          settingType: 'filter',
          fieldToFilter: filter.field,
          operator: operatorObject,
          value: filter.value,
          anchorEl: anchorEl,
        }
      }
      case 'reset':
        return initialState
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const [search, setSearch] = useState('')

  const apiGridRef = useGridApiContext()
  const columns = apiGridRef.current.getAllColumns()

  const handleReset = () => {
    dispatch({ type: 'reset' })
    setSearch('')
  }

  const columnsToShow = useMemo(() => {
    let columnsToFilter = columns
    if (search) {
      columnsToFilter = columnsToFilter?.filter((column) =>
        column.headerName?.toLowerCase().includes(search.toLowerCase())
      )
    }
    return columnsToFilter
  }, [columns, search])

  useEffect(() => {
    if (filterColumnRequested) {
      dispatch({ type: 'setFieldToFilter', payload: filterColumnRequested })
    }
  }, [filterColumnRequested])

  const containerSx = {
    '& > .MuiList-root.MuiMenu-list': {
      py: 0,
    },
    '& .setting-type-selector': {
      py: 0,
      minWidth: `${menuWidth}`,
      '& .MuiListItemIcon-root': {
        minWidth: '32px',
      },
      '& .MuiListItemText-root': {
        '& .MuiListItemText-secondary': {
          opacity: 0.8,
          fontSize: '12px',
          mt: '4px',
        },
        '& .MuiListItemText-primary': {
          fontWeight: 600,
          fontSize: '13px',
        },
      },
    },
    '& .search-container': {
      boxShadow: (theme) => theme.shadows[1],
      '& .MuiInputBase-root': {
        px: '8px',
        py: '4px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: '0px',
      },
    },
    '& .filter-list': {
      minWidth: `${menuWidth}`,
      maxHeight: '300px',
      overflowY: 'auto',
      py: 0,
      background: (theme) => theme.palette.background.default,
      borderBottom: (theme) => `1px solid ${theme.palette.action.hover}`,
      '& .MuiListItemButton-root': {
        py: '2px',
        px: '8px',
        '& .MuiListItemIcon-root': {
          minWidth: '24px',
          '& .tabler-icon': {
            height: '13px',
            color: (theme) => theme.palette.text.primary,
            opacity: 0.9,
          },
        },
        '& .MuiListItemText-root .MuiListItemText-primary': {
          fontSize: '11px',
          letterSpacing: '-0.3px',
          fontWeight: 600,
          opacity: 0.9,
          pt: '1px',
        },
      },
    },

    '& .filter-operator-actions': {
      py: 0,
      backgroundColor: (theme) => theme.palette.background.paper,
      boxShadow: (theme) => theme.shadows[1],
      '& .filter-operator-back-button, .filter-operator-value-row': {
        py: '4px',
        px: '8px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '& .MuiListItemText-root .MuiListItemText-primary': {
          fontSize: '13px',
          letterSpacing: '-0.3px',
          fontWeight: 600,
          opacity: 0.8,
        },
        '& .MuiListItemIcon-root': {
          minWidth: '24px',
        },
      },
    },
    '& .filter-operators': {
      minWidth: `${menuWidth}`,
      maxHeight: '320px',
      overflowY: 'auto',
      py: 0,
      background: (theme) => theme.palette.background.default,
      borderBottom: (theme) => `1px solid ${theme.palette.action.hover}`,
      '& .MuiListItemButton-root': {
        py: '2px',
        '& .MuiListItemText-root .MuiListItemText-primary': {
          fontSize: '11px',
          letterSpacing: '-0.3px',
          fontWeight: 600,
          opacity: 0.9,
          pt: '1px',
        },

        '& .MuiListItemIcon-root': {
          minWidth: '24px',
        },
      },
    },
    '& .apply-filter-button': {
      py: '8px',
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      boxShadow: (theme) => theme.shadows[3],
      '& .MuiListItemIcon-root': {
        minWidth: '24px',
      },
    },
  }

  const filterOperators = useMemo(() => {
    if (state.fieldToFilter) {
      return [
        ...(apiGridRef.current.getColumn(state.fieldToFilter)
          ?.filterOperators || []),
      ]
    }
    return []
  }, [state.fieldToFilter, apiGridRef])

  const InputComponentType = useMemo(() => {
    if (!state.operator) {
      return null
    }
    return filterOperators.find(
      (operator) => operator.value === state.operator.value
    )?.InputComponent
  }, [state.operator, filterOperators])

  const inputComponentProps = useMemo(() => {
    if (!InputComponentType || !state.fieldToFilter || !state.operator) {
      return null
    }
    return {
      apiRef: apiGridRef,
      item: {
        field: state.fieldToFilter,
        operator: state.operator.value,
        value: state.value,
      },
      applyValue: (filterItem: any) => {
        dispatch({ type: 'setFilterData', payload: filterItem })
      },
      focusElementRef: null,
      disabled: false,
      key: state.fieldToFilter,
      size: 'small',
    }
  }, [
    InputComponentType,
    apiGridRef,
    state.fieldToFilter,
    state.operator,
    state.value,
  ])

  const currentState = apiGridRef.current.exportState()
  const currentFilters = currentState.filter.filterModel.items
  const currentGroupingModel = currentState.rowGrouping?.model ?? []
  const currentGroupingField =
    currentGroupingModel.length > 0 ? currentGroupingModel[0] : null

  const handleApplyFilter = () => {
    handleRemoveFilter(
      {
        field: state.fieldToFilter,
        operator: state.operator?.value,
        value: state.value,
      },
      false
    )

    const filterItem = {
      field: state.fieldToFilter,
      operator: state.operator.value,
      value: state.value,
    }
    const currentState = apiGridRef.current.exportState()
    const currentFilters = currentState.filter.filterModel.items
    const uniqueFilters = currentFilters.filter(
      (f) =>
        !(f.field === filterItem.field && f.operator === filterItem.operator)
    )
    const newFilters = [...uniqueFilters, filterItem]
    apiGridRef.current.setFilterModel({ items: newFilters })
    handleReset()
  }

  const handleRemoveFilter = (filter: any, shouldResetState = true) => {
    const currentState = apiGridRef.current.exportState()
    const currentFilters = currentState.filter.filterModel.items
    const newFilters = currentFilters.filter(
      (f) =>
        !(
          f.field === filter.field &&
          f.operator === filter.operator &&
          String(f.value) === String(filter.value)
        )
    )
    apiGridRef.current.setFilterModel({ items: newFilters })
    if (shouldResetState) {
      handleReset()
    }
  }

  const handleRemoveGrouping = () => {
    apiGridRef.current.setRowGroupingModel([])
    if (state.anchorEl) {
      handleReset()
    }
  }

  const handleEditFilter = (
    filter: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    const column = apiGridRef.current.getColumn(filter.field)
    const operatorObject = column.filterOperators?.find(
      (op) => op.value === filter.operator
    )

    if (operatorObject) {
      dispatch({
        type: 'editFilter',
        payload: {
          filter,
          anchorEl: event.currentTarget,
          operatorObject,
        },
      })
    } else {
      logger.warn('Could not find operator object for filter:', { filter })
    }
  }

  const groupingChipSx = { mr: 1 }
  const filterChipBoxSx = { cursor: 'pointer' }
  const mainContainerRowSx = { gap: 1 }
  const filtersRowSx = { gap: 1 }

  return (
    <Row
      sx={mainContainerRowSx}
      className="view-display-settings-container"
    >
      {currentFilters.length > 0 && (
        <Row sx={filtersRowSx}>
          {currentFilters.map((filter) => (
            <Tooltip
              key={`filter-chip-${filter.field}-${filter.operator}-${filter.value}`}
              title={`${
                columns.find((c) => c.field === filter.field)?.headerName
              } ${filter.operator} "${filter.value}" (Click to edit)`}
              arrow={true}
              placement="top"
            >
              <Box
                component="span"
                onClick={(e) => handleEditFilter(filter, e)}
                sx={filterChipBoxSx}
              >
                <Chip
                  className="filters"
                  icon={<IconChartFunnel size={12} />}
                  label={`${
                    columns.find((c) => c.field === filter.field)?.headerName
                  } ${filter.operator} "${filter.value}"`}
                  onDelete={(e) => {
                    e.stopPropagation()
                    handleRemoveFilter(filter)
                  }}
                  deleteIcon={<IconX size={12} />}
                  size="small"
                  variant="outlined"
                />
              </Box>
            </Tooltip>
          ))}
        </Row>
      )}

      {currentGroupingField && (
        <Tooltip
          key={`grouping-chip-${currentGroupingField}`}
          title={`Grouped by ${
            columns.find((c) => c.field === currentGroupingField)?.headerName
          } (Click X to remove)`}
          arrow={true}
          placement="top"
        >
          <Chip
            sx={groupingChipSx}
            icon={<IconTriangleSquareCircleFilled size={14} />}
            className="groupings"
            label={`Grouped by "${
              columns.find((c) => c.field === currentGroupingField)?.headerName
            }"`}
            onDelete={handleRemoveGrouping}
            deleteIcon={<IconX size={12} />}
            size="small"
            variant="outlined"
          />
        </Tooltip>
      )}
      <Tooltip
        title="Filter & group records"
        arrow={true}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            dispatch({ type: 'setAnchorEl', payload: e.currentTarget })
          }}
        >
          <IconChartFunnel size={12} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={state.anchorEl}
        onClose={handleReset}
        open={!!state.anchorEl}
        slotProps={{
          paper: {
            sx: containerSx,
            className: 'view-filters-menu',
          },
        }}
      >
        {!state.settingType && state.anchorEl && (
          <MenuList className="setting-type-selector">
            <ListItemButton
              onClick={() =>
                dispatch({ type: 'setSettingType', payload: 'filter' })
              }
            >
              <ListItemIcon>
                <IconChartFunnel size={20} />
              </ListItemIcon>
              <ListItemText
                primary="Filter Records"
                secondary="Show only rows that match specific criteria (e.g., Status = 'Active', Created > 30 days ago)"
              />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                dispatch({ type: 'setSettingType', payload: 'grouping' })
              }
            >
              <ListItemIcon>
                <IconTriangleSquareCircleFilled size={20} />
              </ListItemIcon>
              <ListItemText
                primary="Group Records"
                secondary="Organize rows into collapsible sections based on shared values (e.g., group by Status, Department, or Date)"
              />
            </ListItemButton>
          </MenuList>
        )}
        {state.settingType === 'filter' && (
          <>
            {!state.fieldToFilter ? (
              <>
                <Box className="search-container">
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch size={16} />
                        </InputAdornment>
                      ),
                    }}
                    value={search}
                    onChange={(e) => {
                      e.stopPropagation()
                      setSearch(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Escape') {
                        e.stopPropagation()
                      }
                    }}
                    fullWidth={true}
                    variant="standard"
                    placeholder="Search properties..."
                    autoFocus
                  />
                </Box>
                <MenuList className="filter-list">
                  {columnsToShow.map((col, index) => (
                    <ListItemButton
                      key={`${index}-column-${col?.field}`}
                      onClick={() =>
                        dispatch({
                          type: 'setFieldToFilter',
                          payload: col?.field,
                        })
                      }
                    >
                      <ListItemIcon>
                        <PropertyTypeIcon
                          objectType={objectType as NativeObjectType}
                          propertyId={col?.field}
                          size={16}
                        />
                      </ListItemIcon>
                      <ListItemText primary={col.headerName} />
                    </ListItemButton>
                  ))}
                </MenuList>
              </>
            ) : (
              <>
                <MenuList className="filter-operator-actions">
                  <ListItemButton
                    className="filter-operator-back-button"
                    onClick={() => {
                      dispatch({ type: 'setFieldToFilter', payload: null })
                      dispatch({ type: 'setOperator', payload: null })
                    }}
                  >
                    <ListItemIcon>
                      <PropertyTypeIcon
                        objectType={objectType as NativeObjectType}
                        propertyId={state.fieldToFilter}
                        size={16}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        columnsToShow.find(
                          (col) => col.field === state.fieldToFilter
                        )?.headerName
                      }
                    />
                  </ListItemButton>
                  {state.operator && (
                    <>
                      <ListItemButton
                        onClick={() => {
                          dispatch({ type: 'setOperator', payload: null })
                        }}
                        className="filter-operator-value-row"
                      >
                        <ListItemIcon>
                          {OperatorMetadataByValue[state.operator.value]
                            ?.icon && (
                            <>
                              {React.createElement(
                                OperatorMetadataByValue[state.operator.value]
                                  ?.icon,
                                {
                                  size: 16,
                                }
                              )}
                            </>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            OperatorMetadataByValue[state.operator.value]
                              ?.label || state.operator.value
                          }
                        />
                      </ListItemButton>
                    </>
                  )}
                </MenuList>
                <MenuList className="filter-operators">
                  {!state.operator &&
                    filterOperators.map((operator) => (
                      <ListItemButton
                        key={operator.value}
                        onClick={() =>
                          dispatch({ type: 'setOperator', payload: operator })
                        }
                      >
                        <ListItemIcon>
                          {OperatorMetadataByValue[operator.value]?.icon && (
                            <>
                              {React.createElement(
                                OperatorMetadataByValue[operator.value]?.icon,
                                {
                                  size: 12,
                                }
                              )}
                            </>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            OperatorMetadataByValue[operator.value]?.label ||
                            operator.value
                          }
                        />
                      </ListItemButton>
                    ))}
                </MenuList>
              </>
            )}

            {state.operator && InputComponentType && inputComponentProps && (
              <InputComponentType {...inputComponentProps} />
            )}

            {state.operator && state.fieldToFilter && (
              <ListItemButton
                disabled={!state.operator || !state.fieldToFilter}
                className="apply-filter-button"
                onClick={handleApplyFilter}
                tabIndex={-1}
              >
                <ListItemIcon>
                  <IconChartFunnel size={16} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    !state.fieldToFilter
                      ? '(Choose property)'
                      : !state.operator
                        ? '(Choose operator)'
                        : 'Add filter'
                  }
                />
              </ListItemButton>
            )}
          </>
        )}
        {state.settingType === 'grouping' && (
          <>
            <Box className="search-container">
              <TextField
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch size={16} />
                    </InputAdornment>
                  ),
                }}
                value={search}
                onChange={(e) => {
                  e.stopPropagation()
                  setSearch(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation()
                  }
                }}
                fullWidth={true}
                variant="standard"
                placeholder="Select property to group by..."
                autoFocus
              />
            </Box>
            <MenuList className="filter-list">
              {columnsToShow
                .filter((col) => col.groupable)
                .map((col, index) => (
                  <ListItemButton
                    key={`${index}-group-column-${col?.field}`}
                    onClick={() => {
                      if (col?.field) {
                        apiGridRef.current.setRowGroupingModel([col.field])
                      } else {
                        logger.warn('Attempted to group by undefined field')
                      }
                      handleReset()
                    }}
                  >
                    <ListItemIcon>
                      <PropertyTypeIcon
                        objectType={objectType as NativeObjectType}
                        propertyId={col?.field}
                        size={16}
                      />
                    </ListItemIcon>
                    <ListItemText primary={col.headerName} />
                  </ListItemButton>
                ))}
            </MenuList>
          </>
        )}
      </Menu>
    </Row>
  )
}

export default ViewFilters
