import type { NativeObjectType } from 'src/lib/objects'

import useObjects from '../Objects/useObjects'
import { objectPropertyDefinitionMetadata } from '../Sidebar/SidebarObjectCreate/objectPropertyDefinitions'

const PropertyTypeIcon = ({
  objectType,
  propertyId,
  propertyTypeId,
  size = 16,
  bold = false,
}: {
  objectType?: NativeObjectType
  propertyId?: string
  propertyTypeId?: string
  size?: number
  bold?: boolean
}) => {
  const { propertyDefinitions } = useObjects()

  const propType =
    propertyTypeId ||
    propertyDefinitions?.[objectType]?.[propertyId]?.propertyTypeId

  return propType
    ? objectPropertyDefinitionMetadata[propType]?.icon &&
        React.createElement(objectPropertyDefinitionMetadata[propType]?.icon, {
          size,
          stroke: bold ? 3.2 : undefined,
        })
    : null
}

export default PropertyTypeIcon
