import { useCallback, useMemo } from 'react'
import { useState } from 'react'

import {
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { IconButton, ListItem, Menu } from '@mui/material'
import {
  IconPlus,
  IconStack,
  IconStack2Filled,
  IconStackFilled,
  IconX,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'

import Row from '../Row/Row'

import useViews from './useViews'

type ViewCreateState = 'pin' | null

const ViewCreate = () => {
  const {
    views,
    pinnedViews,
    createView,
    addPin,
    removePin,
    saving,
    currentView,
  } = useViews()

  const [addViewState, setAddViewState] = useState<ViewCreateState>(null)

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = useCallback(() => {
    setCreateMenuEl(null)
    setAddViewState(null)
  }, [setAddViewState])

  const handleCreateViewFromCurrent = useCallback(async () => {
    if (saving) return

    try {
      const title = `${currentView?.title ?? 'Untitled View'} (copy)`
      const description = currentView?.description ?? ''
      const shareWithWorkspace = currentView?.shareWithWorkspace ?? false

      await createView(
        {
          title,
          description,
          shareWithWorkspace,
        },
        { useCurrentState: true }
      )
      handleMenuClose()
    } catch (error) {
      logger.error('Error creating view from current state:', {
        error: error.message,
        stack: error.stack,
      })
    }
  }, [createView, currentView, handleMenuClose, saving])

  const handleCreateScratchView = useCallback(async () => {
    if (saving) return
    try {
      handleMenuClose()
      await createView(
        {
          title: 'New View',
          description: '',
          shareWithWorkspace: false,
        },
        { useCurrentState: false }
      )
    } catch (error) {
      logger.error('Error creating scratch view:', {
        error: error.message,
        stack: error.stack,
      })
    }
  }, [createView, handleMenuClose, saving])

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
    },
    []
  )

  const pinnedViewsIds = useMemo(() => {
    return pinnedViews?.map((v) => v.id) || []
  }, [pinnedViews])

  const handleTogglePin = useCallback(
    (viewId: string) => {
      if (pinnedViewsIds.includes(viewId)) {
        removePin(viewId)
      } else {
        addPin(viewId)
      }
    },
    [pinnedViewsIds, addPin, removePin]
  )

  const menuSx = useMemo(
    () => ({
      '& .MuiPaper-root': { width: '320px' },
      '& .MuiList-root': { py: 0 },
      '& .MuiListItemText-primary': {
        fontSize: '11px',
        opacity: 0.9,
        letterSpacing: '-0.2px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '24px',
      },
    }),
    []
  )

  const listItemIconSx = useMemo(() => ({ minWidth: '32px' }), [])

  const sectionHeaderSx = useMemo(
    () => ({
      width: '100%',
      justifyContent: 'space-between',
      p: 2,
      pt: 1,
    }),
    []
  )

  const sectionTitleSx = useMemo(
    () => ({
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '-0.4px',
    }),
    []
  )

  const backButtonSx = useMemo(
    () => ({
      p: '3px',
      opacity: 0.5,
      '&:hover': { opacity: 1 },
      borderRadius: '2px',
    }),
    []
  )

  const pinnedItemTextSx = useMemo(() => ({ my: 0 }), [])
  const pinnedItemPrimaryTextSx = useMemo(
    () => ({ fontSize: '12px', fontWeight: 500, letterSpacing: '-0.3px' }),
    []
  )
  const pinnedItemButtonSx = useMemo(() => ({ py: 1 }), [])
  const noViewsTextSx = useMemo(
    () => ({ fontSize: '12px', color: 'text.secondary' }),
    []
  )
  const noViewsListItemSx = useMemo(
    () => ({ py: 2, justifyContent: 'center' }),
    []
  )

  const PinState = () => {
    return [
      <Row
        key="pin-state-header"
        sx={sectionHeaderSx}
        gap={1}
      >
        <Typography sx={sectionTitleSx}>Manage pinned views</Typography>
        <IconButton
          onClick={() => setAddViewState(null)}
          sx={backButtonSx}
        >
          <IconX
            size={16}
            stroke={2.5}
          />
        </IconButton>
      </Row>,
      ...(views.length > 0
        ? views.map((v) => (
            <ListItemButton
              key={v.id}
              onClick={() => handleTogglePin(v.id)}
              disabled={saving}
              sx={pinnedItemButtonSx}
            >
              <ListItemIcon sx={listItemIconSx}>
                <Checkbox
                  checked={pinnedViewsIds.includes(v.id)}
                  size="small"
                />
              </ListItemIcon>
              <ListItemText
                primary={v.title}
                primaryTypographyProps={pinnedItemPrimaryTextSx}
                sx={pinnedItemTextSx}
              />
            </ListItemButton>
          ))
        : [
            <ListItem
              key="no-views"
              sx={noViewsListItemSx}
            >
              <Typography sx={noViewsTextSx}>
                No workspace views found.
              </Typography>
            </ListItem>,
          ]),
    ]
  }

  const ChooseViewState = () => {
    return [
      <ListItemButton
        key="create-scratch-view"
        onClick={handleCreateScratchView}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStack size={16} />
        </ListItemIcon>
        <ListItemText primary="Create view from scratch" />
      </ListItemButton>,
      <ListItemButton
        key="create-view-from-current"
        onClick={handleCreateViewFromCurrent}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStackFilled size={16} />
        </ListItemIcon>
        <ListItemText primary="Save current as new view" />
      </ListItemButton>,
      <ListItemButton
        key="workspace-views"
        onClick={() => setAddViewState('pin')}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStack2Filled size={16} />
        </ListItemIcon>
        <ListItemText primary={`Workspace views`} />
      </ListItemButton>,
    ]
  }

  return (
    <>
      <Tooltip
        title="Add view"
        arrow={true}
        placement="top"
      >
        <IconButton
          onClick={handleOpenMenu}
          className="flat"
          disabled={saving}
        >
          <IconPlus size={14} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={createMenuEl}
        open={Boolean(createMenuEl)}
        onClose={handleMenuClose}
        sx={menuSx}
      >
        {!addViewState && <ChooseViewState />}

        {addViewState === 'pin' && <PinState />}
      </Menu>
    </>
  )
}

export default ViewCreate
