import type { DayObject } from 'types/graphql'

import { NativeObjectTypes } from 'src/lib/objects'
import { objectIdsFromObjectsArrayAsString } from 'src/lib/Properties/formatters'

import {
  EnumRenderer,
  LinkedInUrlRenderer,
  MultiObjectRenderer,
  ObjectChipRenderer,
  StringArrayRenderer,
  StringArrayValueFormatter,
} from './StandardPropertiesRenderers'

const propertyDisplayOverrides = {
  [NativeObjectTypes.Person]: {
    email: {
      propertyRenderer: ObjectChipRenderer,
    },
    linkedInUrl: {
      tableWidth: 48,
      propertyRenderer: LinkedInUrlRenderer,
    },
    firstName: {
      tableWidth: 80,
    },
    lastName: {
      tableWidth: 80,
    },
  },
  [NativeObjectTypes.Organization]: {
    name: {
      propertyRenderer: ObjectChipRenderer,
    },
    promises: {
      propertyRenderer: StringArrayRenderer,
      tableValueFormatter: StringArrayValueFormatter,
    },
    specialities: {
      propertyRenderer: StringArrayRenderer,
      tableValueFormatter: StringArrayValueFormatter,
    },
    doesBusinessWith: {
      propertyRenderer: StringArrayRenderer,
      tableValueFormatter: StringArrayValueFormatter,
    },
    domain: {
      propertyRenderer: ObjectChipRenderer,
    },
    founded: {
      stringFormatter: (value: string) => {
        return value ? parseInt(value).toString() : null
      },
    },
    industry: {
      tableValueFormatter: (value: string) => {
        return value
      },
    },
  },
  [NativeObjectTypes.MeetingRecording]: {
    people: {
      tableWidth: 138,
      propertyRenderer: MultiObjectRenderer,
      tableValueFormatter: (objects: DayObject[]) => {
        return objectIdsFromObjectsArrayAsString(objects)
      },
    },
    domains: {
      tableWidth: 138,
      propertyRenderer: MultiObjectRenderer,
      tableValueFormatter: (objects: DayObject[]) => {
        return objectIdsFromObjectsArrayAsString(objects)
      },
    },
    statusLabel: {
      propertyRenderer: EnumRenderer,
      tableWidth: 112,
    },
    statusReason: {
      propertyRenderer: EnumRenderer,
      tableWidth: 212,
    },
    userWasInMeetings: {
      tableWidth: 72,
    },
  },
}

export default propertyDisplayOverrides
